import React from "react";
import Select from "react-select";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  TabContent,
  TabPane,
  Container,
  Collapse,
  Row,
  Col,
  Tab,
} from "react-bootstrap";
import ReactDatetime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import {
  ComposedChart,
  Bar,
  LabelList,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
} from "recharts";
import { getCabangAktif, grafikCsVisit } from "../../../stores";
import { useHistory } from "react-router-dom";

import moment from "moment";

function Panels() {
  const dispatch = useDispatch();
  const history = useHistory();
  const csVisit = useSelector((state) => state.csVisitReducer);
  const cabang = useSelector((state) => state.cabangReducer);
  const date = new Date();
  const [dariTgl, setDariTgl] = React.useState(
    // new Date().setHours(0, 0, 0, 0)
    new Date(date.getFullYear(), date.getMonth(), 1).setHours(0, 0, 0, 0)
  );
  const [smpTgl, setSmpTgl] = React.useState(
    // new Date().setHours(23, 59, 0, 0)
    new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(23, 59, 0, 0)
  );
  const [grafikCsVisitReview, setGrafikCsVisitReview] = React.useState(null);
  const auth = useSelector((state) => state.authReducer);
  const [idCabang, setIdCabang] = React.useState([]);
  const [listCabang, setListCabang] = React.useState([]);

  // const [kpi2Option, setKpi2Option] = React.useState(null);
  // const [kpi3Option, setKpi3Option] = React.useState(null);

  React.useEffect(() => {
    if (csVisit.grafikCsVisit) {
      setGrafikCsVisitReview(csVisit.grafikCsVisit.data);
    }
  }, [csVisit.grafikCsVisit]);

  React.useEffect(() => {
    grafikCsVisit(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
      idCabangWeb: idCabang.value,
    });
  }, [dariTgl, smpTgl, idCabang]);
  React.useEffect(() => {
    getCabangAktif(dispatch);
  }, []);

  React.useEffect(() => {
    let tmp = cabang.getCabangAktif.map((val, index) => {
      return {
        // ...val,
        label: `${val.namaCabang} - ${val.namaClient}`,
        value: val.idCabang,
      };
    });

    setListCabang(tmp);
    tmp.map((val, index) => {
      if (index === 0) {
        setIdCabang(val);
      }
    });
  }, [cabang.getCabangAktif]);
  return (
    <>
      <Container fluid>
        <Row className="mb-3">
          <Col md="3">
            <h4 className="title">Start Date</h4>
            <Form.Group>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Date Picker Here",
                }}
                onChange={(e) => {
                  setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                }}
                value={dariTgl}
                initialValue={dariTgl}
                timeFormat={false}
              ></ReactDatetime>
            </Form.Group>
          </Col>
          <Col md="3">
            <h4 className="title">End Date</h4>
            <Form.Group>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Date Picker Here",
                }}
                onChange={(e) => {
                  setSmpTgl(e.toDate().setHours(23, 59, 0, 0));
                }}
                value={smpTgl}
                initialValue={smpTgl}
                timeFormat={false}
              ></ReactDatetime>
            </Form.Group>
          </Col>
        </Row>

        {csVisit.grafikCsVisit && (
          <Row>
            <Col>
              <Card>
                <Card.Header>
                  <Row>
                    <Col>
                      <Card.Title as="h4">Findings Recapitulation</Card.Title>
                    </Col>
                    {auth.role === "all" ||
                    auth.role === "gsc" ||
                    auth.role === "market" ? (
                      <>
                        <Col md="6">
                          <Select
                            className="react-select primary pb-2"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            onChange={(value) => {
                              setIdCabang(value);
                            }}
                            value={idCabang}
                            options={[...listCabang]}
                            placeholder="Single Select"
                          />
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>
                </Card.Header>
                <Card.Body>
                  <Row className="justify-content-center">
                    <Col
                      md={4}
                      // key={index}
                      className="mb-3 d-flex justify-content-center"
                    >
                      <div
                        className="numbers d-flex flex-column align-items-center p-2"
                        style={{
                          border: "1px solid #ccc", // Garis tepi
                          borderRadius: "8px", // Sudut melengkung
                          width: "100%", // Sesuaikan lebar
                          maxWidth: "200px", // Lebar maksimal
                          padding: "10px", // Padding untuk jarak di dalam kotak
                          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)", // Bayangan halus
                          textAlign: "center", // Teks di tengah horizontal
                        }}
                      >
                        <Row>
                          <div className="d-flex flex-column align-items-center">
                            <p
                              className="card-category"
                              style={{
                                fontWeight: "bold",
                                color: "black",
                                margin: "0 0 5px 0", // Margin bawah untuk jarak antar teks
                              }}
                            >
                              Status:
                            </p>
                            <Card.Title as="h4" className="m-0"></Card.Title>
                          </div>
                          <div className="d-flex flex-column align-items-center ml-2">
                            <p
                              className="card-category"
                              style={{
                                fontWeight: "bold",
                                color:
                                  csVisit.grafikCsVisit.status === "Below"
                                    ? "red"
                                    : csVisit.grafikCsVisit.status === "Meet"
                                    ? "orange"
                                    : "green",
                                margin: 0,
                              }}
                            >
                              {csVisit.grafikCsVisit.status}
                            </p>
                          </div>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {grafikCsVisit && (
                        <div style={{ width: "100%", height: 300 }}>
                          <ResponsiveContainer>
                            <ComposedChart data={grafikCsVisitReview}>
                              <XAxis dataKey="nama" />
                              <YAxis />
                              <Tooltip />
                              <Legend verticalAlign="top" height={36} />
                              <CartesianGrid stroke="#f5f5f5" />
                              <Bar
                                dataKey="Exceed"
                                barSize={20}
                                fill="#006605"
                                stackId="c"
                              ></Bar>
                              <Bar
                                dataKey="Meet"
                                barSize={20}
                                fill="#ff8200"
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey="Below"
                                barSize={20}
                                fill="#ff002f"
                                stackId="b"
                              ></Bar>
                            </ComposedChart>
                          </ResponsiveContainer>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/csvisit");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant={
                          csVisit.grafikCsVisit.pencapaian <
                          csVisit.grafikCsVisit.target
                            ? "danger"
                            : "info"
                        }
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}

export default Panels;
