const initialState = {
  listArea: [],
  listRuangan: [],
  jamLaporan: [],
  listLaporan: [],
  totalVisitHariIni: "-",
  totalProblem: "-",
  kpi1: null,
  kpi2: null,
  kpi3: null,
  kpi4: null,
  listKategori: [],
  listProjectAll: [],
  rekapProblem: [],
  rekapKeluhan: [],
  timeline: null,
  rekapLaporan: [],
  calendar: [],
  proposal: [],
  listRuangPatroli: [],
  listReportPatroli: [],
  kpiPatroli: null,
  getTargetPatroli: [],
  listShift: [],
  kpiDashboard: null,
};

const monitoringReducer = (state = initialState, action) => {
  let { type, data } = action;

  switch (type) {
    case "SET_TIMELINE":
      return {
        ...state,
        timeline: data,
      };
    case "SET_LISTAREA":
      return {
        ...state,
        listArea: data,
      };
    case "SET_LISTRUANGPATROLI":
      return {
        ...state,
        listRuangPatroli: data,
      };
    case "SET_LISTRUANGAN":
      return {
        ...state,
        listRuangan: data,
      };
    case "SET_JAMLAPORAN":
      return {
        ...state,
        jamLaporan: data,
      };
    case "SET_LISTLAPORAN":
      return {
        ...state,
        listLaporan: data,
      };
    case "SET_TOTALVISITHARIINI":
      return {
        ...state,
        totalVisitHariIni: data,
      };
    case "SET_TOTALPROBLEM":
      return {
        ...state,
        totalProblem: data,
      };
    case "SET_KPI1":
      return {
        ...state,
        kpi1: data,
      };
    case "SET_KPI2":
      return {
        ...state,
        kpi2: data,
      };
    case "SET_KPI3":
      return {
        ...state,
        kpi3: data,
      };
    case "SET_KPI4":
      return {
        ...state,
        kpi4: data,
      };
    case "SET_LISTKATEGORI":
      return {
        ...state,
        listKategori: data,
      };

    case "SET_PROJECTALL":
      return {
        ...state,
        listProjectAll: data,
      };

    case "SET_REKAPPROBLEM":
      return {
        ...state,
        rekapProblem: data,
      };

    case "SET_REKAPKELUHAN":
      return {
        ...state,
        rekapKeluhan: data,
      };
    case "SET_REKAPLAPORAN":
      return {
        ...state,
        rekapLaporan: data,
      };
    case "SET_CALENDAR":
      return {
        ...state,
        calendar: data,
      };
    case "SET_PROPOSAL":
      return {
        ...state,
        proposal: data,
      };
    case "SET_PATROLIREPORT":
      return {
        ...state,
        listReportPatroli: data,
      };
    case "SET_KPIPATROLI":
      return {
        ...state,
        kpiPatroli: data,
      };

    case "SET_GETTARGETPATROLI":
      return {
        ...state,
        getTargetPatroli: data,
      };
    case "SET_SHIFTDROPDOWN":
      return {
        ...state,
        listShift: data,
      };

    case "SET_KPIDASHBOARD":
      return {
        ...state,
        kpiDashboard: data,
      };
    default:
      return state;
  }
};

export default monitoringReducer;
