const initialState = {
  username: "",
  perusahaan: "",
  cabang: "",
  menu: [],
};

const authReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_IDENTITY":
      return {
        ...state,
        username: data.username,
        cabang: data.cabang,
        perusahaan: data.perusahaan,
        menu: data.menu,
        role: data.role,
        idCabang: data.idCabang,
      };
    default:
      return state;
  }
};

export default authReducer;
