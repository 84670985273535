import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import moment from "moment";

export function createReport(data) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEnterKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .post("incident/createreportweb", data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      });
    })
    .then(() => {
      window.location.reload();
    })
    .catch(errorHandler);
}
export function updateIncidentReport(data) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEnterKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .post("incident/updateincident", data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      });
    })
    .then(() => {
      window.location.reload();
    })
    .catch(errorHandler);
}

export function getReportIncident(dispacth, data) {
  baseAxios
    .get(
      // `/incident/getincident?idCabangWeb=${data.idCabang}&incidentStatus=${data.incidentStatus}&typeIncident=${data.typeIncident}`,
      `/incident/getincident`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    )
    .then(async (respon) => {
      dispacth({ type: "SET_GETREPORTINCIDENT", data: respon.data });
    });
}

export function getGrafikIncident(dispatch, data) {
  baseAxios
    .get(
      `/incident/getgrafikincident?dari=${data.dariTgl}&sampai=${data.smpTgl}&idCabangWeb=${data.idCabangWeb}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    )
    .then(async (respon) => {
      dispatch({ type: "SET_GRAFIKINCIDENT", data: respon.data });
    })
    .catch(errorHandler);
}

export async function exportIncident(data) {
  try {
    const response = await baseAxios.post("/incident/expoertincident", data, {
      headers: { token: localStorage.getItem("tokenjti") },
      responseType: "blob",
    });
    // console.log(response, "AAA");
    return response.data;
  } catch (err) {
    return err.response;
  }
}
