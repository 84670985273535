const initialState = {
  getReport: [],
  getGrafikMonthlyReport: null,
};
const monthlyReportReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (action.type) {
    case "SET_GETREPORT":
      return {
        ...state,
        getReport: data,
      };

    case "SET_GRAFIKMONTHLYREPORT":
      return {
        ...state,
        getGrafikMonthlyReport: data,
      };
    default:
      return state;
  }
};
export default monthlyReportReducer;
