import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
  Tab,
  Tabs,
} from "react-bootstrap";
import Select from "react-select";
import "./style.css";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import {
  createReport,
  getTraining,
  editTraining,
  kirimReportTraining,
  getCabangAktif,
  getReportIncident,
  updateIncidentReport,
  getList,
  createFormChecklist,
  getLaporanChecklist,
} from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import Swal from "sweetalert2";
import { compose } from "redux";

function ReactTables() {
  const dispatch = useDispatch();
  const date = new Date();
  const uniformChecklist = useSelector(
    (state) => state.uniformChecklistReducer
  );
  const cabang = useSelector((state) => state.cabangReducer);
  const [modal, setModal] = React.useState(false);
  const [modalChecklist, setModalChecklist] = React.useState(false);
  const [modalEditTraining, setModalEditTraining] = React.useState(false);
  const [modalTrainingApprove, setModalTrainingApprove] = React.useState(false);
  const [dataFormChecklist, setDataFormChechklist] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [deskripsi, setDeskripsi] = React.useState("");
  const [jenis, setJenis] = React.useState("");
  const [trainingId, setTrainingId] = React.useState("");
  const [deskripsiTraining, setDeskripsiTraining] = React.useState("");
  const [noteTraining, setNoteTraining] = React.useState("");
  const [dariTgl, setDariTgl] = React.useState(
    // new Date(date.getFullYear(), date.getMonth(), 1).setHours(0, 0, 0, 0)
    new Date()
  );
  const [modalPreviewFoto, setModalPreviewFoto] = React.useState(false);
  const [smpTgl, setSmpTgl] = React.useState(new Date().setHours(0, 0, 0, 0));
  const [gambar, setGambar] = React.useState([]);
  const [kategoriKejadian, setKategroiKejadian] = React.useState({
    value: "",
    label: "Choose the Category",
    isDisabled: true,
  });
  const respon = "Done";
  const [client, setClient] = React.useState({
    value: "",
    label: "Choose the Client",
    isDisabled: true,
  });
  const [type, setType] = React.useState({
    value: "",
    label: "Choose the Type of Incident",
    isDisabled: true,
  });
  const [tindakan, setTindakan] = React.useState({
    value: "",
    label: "Choose the Action",
    isDisabled: true,
  });
  const [listCabang, setListCabang] = React.useState([]);
  const [namaAnggota, setNamaAnggota] = React.useState("");
  const [nopeg, setNopeg] = React.useState("");
  const [dropMajor, setDropMajor] = React.useState(false);
  const [dropNonMajor, setDropNonMajor] = React.useState(false);
  let tanggalSekarang = new Date();
  const [tab, setTab] = React.useState([]);
  const [dataChecklist, setDataChecklist] = React.useState([]);
  const [formChecklist, setFormChecklist] = React.useState(false);
  const [nilaiChecklist, setNilaiChecklist] = React.useState([]);
  const [modalConfirmChecklist, setModalConfirmChecklist] =
    React.useState(false);
  const [modalDetailChecklist, setModalDetailChecklist] = React.useState(false);

  function submitFormChecklist(e) {
    e.preventDefault();

    Swal.fire({
      title: "Konfirmasi",
      text: "Apakah anda yakin ingin mengunggah data form checklist?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        createFormChecklist({
          namaAnggota: namaAnggota,
          nopeg: nopeg,
          idCabang: client.idCabang,
          idClient: client.idClient,
          namaCabang: client.namaCabang,
          namaClient: client.namaClient,
          nilaiChecklistArr: JSON.stringify(nilaiChecklist),
          deskripsi: deskripsi,
          tanggal: new Date(smpTgl).toISOString(),
        }).then((response) => {
          if (response.status === 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: response.message,
              showConfirmButton: false,
              timer: 1500,
            });
            // getSidak(dispatch);
            setModalSidak(false);
          } else if (response.status === 400) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response.data.message,
            });
          }
        });
      }
    });
  }

  function handleOpenModal(e) {
    setClient({
      value: "",
      label: "Choose the Client",
      isDisabled: true,
    });
    setDeskripsi("");
    setGambar([]);
    setNamaAnggota("");
    setDariTgl(new Date());
    setDropMajor(false);
    setDropNonMajor(false);
    setKategroiKejadian({
      value: "",
      label: "Choose the Category",
      isDisabled: true,
    });
    setType({
      value: "",
      label: "Choose the Type of Incident",
      isDisabled: true,
    });
  }
  React.useEffect(() => {
    getCabangAktif(dispatch);
    // getReportIncident(dispatch);
  }, []);

  React.useEffect(() => {
    let tmp = cabang.getCabangAktif.map((val, index) => {
      return {
        ...val,
        label: `${val.namaCabang} - ${val.namaClient}`,
        value: val.idCabang,
      };
    });
    setListCabang(tmp);
  }, [cabang.getCabangAktif]);
  React.useEffect(() => {
    let tmp = uniformChecklist.getListChecklist.map((val, index) => {
      return {
        ...val,
        kategori:
          val.kategoriChecklist === "A"
            ? "Posisi dan Keberadaan"
            : val.kategoriChecklist === "B"
            ? "Administrasi"
            : val.kategoriChecklist === "C"
            ? "Penampilan"
            : val.kategoriChecklist === "D"
            ? "Keahlian"
            : "Kebersihan",
        nilai: 0,
      };
    });
    setDataChecklist(tmp);
    setNilaiChecklist(tmp);
  }, [uniformChecklist.getListChecklist]);
  React.useEffect(() => {
    let tmp = uniformChecklist.getLaporanChecklist.map((val, index) => {
      return {
        ...val,
        tanggal: moment(val.tanggal).format("DD MMM YYYY"),

        actions: (
          <div className="actions-right">
            <Button
              onClick={() => {
                setModalDetailChecklist(true);
                setDataFormChechklist(val);
              }}
              variant="success"
              size="md"
              className="btn-link remove text-success"
            >
              <i className="fa fa-info-circle fa-lg" />
            </Button>{" "}
          </div>
        ),
      };
    });
    setData(tmp);
  }, [uniformChecklist.getLaporanChecklist]);

  const history = useHistory();

  React.useEffect(() => {
    getList(dispatch);
    getLaporanChecklist(dispatch);
  }, []);

  const handleInputNilai = (index, kategoriChecklist, namaChecklist, nilai) => {
    setNilaiChecklist((hasil) => {
      const updatedValues = hasil.map((item) => {
        if (item.namaChecklist === namaChecklist) {
          return {
            ...item,
            nilai: nilai,
          };
        }
        return item;
      });
      return updatedValues;
    });
    // setNilaiChecklist((hasil) => {
    //   const updatedValues = hasil.filter(
    //     (item) => item.namaChecklist !== namaChecklist
    //   );
    //   updatedValues.push({ kategoriChecklist, namaChecklist, nilai });
    //   return updatedValues;
    // });
  };
  return (
    <>
      <div
        id="previewImage"
        style={{
          display: modalPreviewFoto ? "block" : "none",
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          zIndex: "9999",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={modalPreviewFoto}
          alt="Preview"
          style={{
            borderRadius: "5px",
            boxShadow: "4px 4px 8px 0px rgba(0,0,0,0.75)",
            maxHeight: "80%",
            maxWidth: "80%",
            objectFit: "contain",
            background: "transparent",
            position: "absolute",
            top: "50%", // Posisi vertikal ke tengah
            left: "50%", // Posisi horizontal ke tengah
            transform: "translate(-50%, -50%)",
          }}
        />
        <button
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            fontSize: "20px",
            cursor: "pointer",
          }}
          onClick={() => setModalPreviewFoto("")}
        >
          &times;
        </button>
      </div>
      <Container fluid>
        {/* <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModal(true);
          }}
          variant="primary"
        >
          Pengajuan Project
        </Button> */}
        <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModalChecklist(true);
            handleOpenModal();
          }}
          variant="primary"
        >
          Add Checklist
        </Button>
        <Row>
          <Col md="12">
            <h4 className="title">Checklist Control</h4>
            <Card>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Employee Name",
                      accessor: "namaAnggota",
                    },
                    {
                      Header: "Number ID",
                      accessor: "nopeg",
                    },
                    {
                      Header: "Branch",
                      accessor: "namaCabang",
                    },
                    {
                      Header: "Client",
                      accessor: "namaClient",
                    },
                    {
                      Header: "Date",
                      accessor: "tanggal",
                    },
                    {
                      Header: "Submitter",
                      accessor: "dilaporkanOleh",
                    },

                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        size="lg"
        show={modalDetailChecklist}
        onHide={() => {
          setModalDetailChecklist(false);
        }}
        backdrop={false}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Detail Form Checklist
          </Modal.Title>
        </Modal.Header>
        <div style={{ maxHeight: "800px", overflowY: "scroll" }}>
          <Modal.Body>
            <Row>
              <Col md="12">
                <Form action="" className="form" method="">
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Employee Name
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataFormChecklist.namaAnggota}</Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Number ID
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataFormChecklist.nopeg}</Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Branch
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataFormChecklist.namaCabang}</Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Client
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataFormChecklist.namaClient}</Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Date
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">
                          {moment(dataFormChecklist.tanggal).format(
                            "DD MMM YYYY"
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Submitter
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataFormChecklist.dilaporkanOleh}</Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Description
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataFormChecklist.deskripsi}</Col>
                      </Row>
                      <Row>
                        <Col
                          sm="5"
                          style={{ marginTop: 15, fontWeight: "bold" }}
                        >
                          Form Checklist
                        </Col>
                      </Row>
                      {dataFormChecklist?.nilaiChecklistArr &&
                        Object.entries(
                          dataFormChecklist.nilaiChecklistArr.reduce(
                            (acc, curr) => {
                              const {
                                kategoriChecklist,
                                namaChecklist,
                                nilai,
                              } = curr;

                              if (!acc[kategoriChecklist]) {
                                acc[kategoriChecklist] = [];
                              }

                              acc[kategoriChecklist].push({
                                namaChecklist,
                                nilai,
                              });
                              return acc;
                            },
                            {}
                          )
                        ).map(([kategori, checklists], index) => (
                          <div key={index}>
                            <h5 style={{ marginBottom: 1, fontWeight: "bold" }}>
                              {kategori === "A"
                                ? "A. Posisi dan Keberadaan"
                                : kategori === "B"
                                ? "B. Administrasi"
                                : kategori === "C"
                                ? "C. Penampilan"
                                : kategori === "D"
                                ? "D. Keahlian"
                                : "E. Kebersihan"}
                            </h5>
                            {checklists.map((checklist, i) => {
                              const nilai2 =
                                checklist.nilai === 0 ? "-" : checklist.nilai;
                              return (
                                <>
                                  <Row key={i}>
                                    <Col sm="5">
                                      • {checklist.namaChecklist}
                                    </Col>
                                    <Col sm="0.5">:</Col>
                                    <Col sm="6">{nilai2}</Col>
                                  </Row>
                                </>
                              );
                            })}
                            {/* <ul>
                              {checklists.map((checklist, i) => (
                                <li key={i}>
                                  {checklist.namaChecklist}: {checklist.nilai}
                                </li>
                              ))}
                            </ul>
                            <ul>
                              {checklists.map((checklist, i) => (
                                <li key={i}>
                                  {checklist.namaChecklist}: {checklist.nilai}
                                </li>
                              ))}
                            </ul> */}
                          </div>
                        ))}
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Modal.Body>
        </div>
      </Modal>

      <Modal
        size="lg"
        show={modalChecklist}
        backdrop="static"
        onHide={() => {
          setModalChecklist(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
          <Card.Title as="h4" style={{ fontWeight: "bold" }}>
            Form Control Checklist
          </Card.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header></Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="6">
                        <Form.Group>
                          <label>Employee Name</label>
                          <Form.Control
                            onChange={(e) => {
                              setNamaAnggota(e.target.value);
                            }}
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group>
                          <label>Client's Name</label>
                          <Select
                            className="react-select primary pb-2"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            onChange={(value) => {
                              setClient(value);
                            }}
                            value={client}
                            options={[
                              {
                                value: "",
                                label: "Choose the Client",
                                isDisabled: true,
                              },
                              ...listCabang,
                              "-",
                            ]}
                            placeholder="Single Select"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm="6">
                        <Form.Group>
                          <label>NIK / Nopeg</label>
                          <Form.Control
                            onChange={(e) => {
                              setNopeg(e.target.value);
                            }}
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group>
                          <label>Date</label>
                          <Form.Group>
                            <ReactDatetime
                              inputProps={{
                                className: "form-control",
                                placeholder: "Date Picker Here",
                              }}
                              onChange={(e) => {
                                setSmpTgl(e.toDate().setHours(0, 0, 0, 0));
                              }}
                              value={smpTgl}
                              initialValue={smpTgl}
                              timeFormat={false}
                            ></ReactDatetime>
                          </Form.Group>
                        </Form.Group>
                      </Col>
                    </Row>
                    <div className="clearfix"></div>

                    <div className="clearfix"></div>
                    <Row>
                      <Col sm="2" style={{ fontWeight: "bold" }}>
                        Note Nilai
                      </Col>
                      <Col sm="0.5">:</Col>
                      {/* <Col sm="6">{"aaa"}</Col> */}
                    </Row>
                    <Row>
                      <Col sm="2" style={{}}>
                        50
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="6">Kondisi Tidak Baik</Col>
                    </Row>
                    <Row>
                      <Col sm="2" style={{}}>
                        75
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="6">Kondisi Kurang Baik</Col>
                    </Row>
                    <Row>
                      <Col sm="2" style={{}}>
                        100
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="6">Kondisi Baik</Col>
                    </Row>
                    {/* {formChecklist && ( */}
                    <>
                      <Tabs
                        defaultActiveKey="A"
                        id="justify-tab-example"
                        className="mb-3 custom-tab"
                        justify
                      >
                        <Tab eventKey="A" title="A. Posisi dan Keberadaan">
                          <Row>
                            {dataChecklist.map((val, index) => {
                              if (val.kategoriChecklist === "A") {
                                return (
                                  <>
                                    <Col sm="6">
                                      <Card>
                                        <Card.Body>
                                          <Row>
                                            <Col>
                                              {val.namaChecklist}
                                              <Row
                                                sm="12"
                                                style={{
                                                  marginTop: 10,
                                                  marginLeft: -35,
                                                }}
                                              >
                                                <Form.Check>
                                                  <Form.Check.Label
                                                    style={{
                                                      color: "black",
                                                    }}
                                                  >
                                                    <Form.Check.Input
                                                      name={`nilai_${index}`}
                                                      type="radio"
                                                      value="50"
                                                      onChange={(e) =>
                                                        handleInputNilai(
                                                          index,
                                                          val.kategoriChecklist,
                                                          val.namaChecklist,
                                                          e.target.value
                                                        )
                                                      }
                                                    ></Form.Check.Input>
                                                    <span className=""></span>
                                                    50
                                                  </Form.Check.Label>
                                                </Form.Check>
                                                <Form.Check>
                                                  <Form.Check.Label
                                                    style={{
                                                      color: "black",
                                                    }}
                                                  >
                                                    <Form.Check.Input
                                                      name={`nilai_${index}`}
                                                      type="radio"
                                                      value="75"
                                                      onChange={(e) =>
                                                        handleInputNilai(
                                                          index,
                                                          val.kategoriChecklist,
                                                          val.namaChecklist,
                                                          e.target.value
                                                        )
                                                      }
                                                    ></Form.Check.Input>
                                                    <span className=""></span>
                                                    75
                                                  </Form.Check.Label>
                                                </Form.Check>
                                                <Form.Check>
                                                  <Form.Check.Label
                                                    style={{
                                                      color: "black",
                                                    }}
                                                  >
                                                    <Form.Check.Input
                                                      name={`nilai_${index}`}
                                                      type="radio"
                                                      value="100"
                                                      onChange={(e) =>
                                                        handleInputNilai(
                                                          index,
                                                          val.kategoriChecklist,
                                                          val.namaChecklist,
                                                          e.target.value
                                                        )
                                                      }
                                                    ></Form.Check.Input>
                                                    <span className=""></span>
                                                    100
                                                  </Form.Check.Label>
                                                </Form.Check>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </Card.Body>
                                      </Card>
                                    </Col>
                                  </>
                                );
                              }
                            })}
                          </Row>
                        </Tab>
                        <Tab eventKey="B" title="B. Administrasi">
                          <Row>
                            {dataChecklist.map((val, index) => {
                              if (val.kategoriChecklist === "B") {
                                return (
                                  <>
                                    <Col sm="6" key={index}>
                                      <Card>
                                        <Card.Body>
                                          <Row>
                                            <Col>
                                              {val.namaChecklist}

                                              <Row
                                                sm="12"
                                                style={{
                                                  marginTop: 10,
                                                  marginLeft: -35,
                                                }}
                                              >
                                                <Form.Check>
                                                  <Form.Check.Label
                                                    style={{
                                                      color: "black",
                                                    }}
                                                  >
                                                    <Form.Check.Input
                                                      name={`nilai_${index}`}
                                                      type="radio"
                                                      value="50"
                                                      onChange={(e) =>
                                                        handleInputNilai(
                                                          index,
                                                          val.kategoriChecklist,
                                                          val.namaChecklist,
                                                          e.target.value
                                                        )
                                                      }
                                                    ></Form.Check.Input>
                                                    <span className=""></span>
                                                    50
                                                  </Form.Check.Label>
                                                </Form.Check>
                                                <Form.Check>
                                                  <Form.Check.Label
                                                    style={{
                                                      color: "black",
                                                    }}
                                                  >
                                                    <Form.Check.Input
                                                      name={`nilai_${index}`}
                                                      type="radio"
                                                      value="75"
                                                      onChange={(e) =>
                                                        handleInputNilai(
                                                          index,
                                                          val.kategoriChecklist,
                                                          val.namaChecklist,
                                                          e.target.value
                                                        )
                                                      }
                                                    ></Form.Check.Input>
                                                    <span className=""></span>
                                                    75
                                                  </Form.Check.Label>
                                                </Form.Check>
                                                <Form.Check>
                                                  <Form.Check.Label
                                                    style={{
                                                      color: "black",
                                                    }}
                                                  >
                                                    <Form.Check.Input
                                                      name={`nilai_${index}`}
                                                      type="radio"
                                                      value="100"
                                                      onChange={(e) =>
                                                        handleInputNilai(
                                                          index,
                                                          val.kategoriChecklist,
                                                          val.namaChecklist,
                                                          e.target.value
                                                        )
                                                      }
                                                    ></Form.Check.Input>
                                                    <span className=""></span>
                                                    100
                                                  </Form.Check.Label>
                                                </Form.Check>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </Card.Body>
                                      </Card>
                                    </Col>
                                  </>
                                );
                              }
                            })}
                          </Row>
                        </Tab>
                        <Tab eventKey="C" title="C. Penampilan">
                          <Row>
                            {dataChecklist.map((val, index) => {
                              if (val.kategoriChecklist === "C") {
                                return (
                                  <>
                                    <Col sm="6">
                                      <Card>
                                        <Card.Body>
                                          <Row>
                                            <Col>
                                              {val.namaChecklist}

                                              <Row
                                                sm="12"
                                                style={{
                                                  marginTop: 10,
                                                  marginLeft: -35,
                                                }}
                                              >
                                                <Form.Check>
                                                  <Form.Check.Label
                                                    style={{
                                                      color: "black",
                                                    }}
                                                  >
                                                    <Form.Check.Input
                                                      name={`nilai_${index}`}
                                                      type="radio"
                                                      value="50"
                                                      onChange={(e) =>
                                                        handleInputNilai(
                                                          index,
                                                          val.kategoriChecklist,
                                                          val.namaChecklist,
                                                          e.target.value
                                                        )
                                                      }
                                                    ></Form.Check.Input>
                                                    <span className=""></span>
                                                    50
                                                  </Form.Check.Label>
                                                </Form.Check>
                                                <Form.Check>
                                                  <Form.Check.Label
                                                    style={{
                                                      color: "black",
                                                    }}
                                                  >
                                                    <Form.Check.Input
                                                      name={`nilai_${index}`}
                                                      type="radio"
                                                      value="75"
                                                      onChange={(e) =>
                                                        handleInputNilai(
                                                          index,
                                                          val.kategoriChecklist,
                                                          val.namaChecklist,
                                                          e.target.value
                                                        )
                                                      }
                                                    ></Form.Check.Input>
                                                    <span className=""></span>
                                                    75
                                                  </Form.Check.Label>
                                                </Form.Check>
                                                <Form.Check>
                                                  <Form.Check.Label
                                                    style={{
                                                      color: "black",
                                                    }}
                                                  >
                                                    <Form.Check.Input
                                                      name={`nilai_${index}`}
                                                      type="radio"
                                                      value="100"
                                                      onChange={(e) =>
                                                        handleInputNilai(
                                                          index,
                                                          val.kategoriChecklist,
                                                          val.namaChecklist,
                                                          e.target.value
                                                        )
                                                      }
                                                    ></Form.Check.Input>
                                                    <span className=""></span>
                                                    100
                                                  </Form.Check.Label>
                                                </Form.Check>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </Card.Body>
                                      </Card>
                                    </Col>
                                  </>
                                );
                              }
                            })}
                          </Row>
                        </Tab>
                        <Tab eventKey="D" title="D. Keahlian">
                          <Row>
                            {dataChecklist.map((val, index) => {
                              if (val.kategoriChecklist === "D") {
                                return (
                                  <>
                                    <Col sm="6">
                                      <Card>
                                        <Card.Body>
                                          <Row>
                                            <Col>
                                              {val.namaChecklist}

                                              <Row
                                                sm="12"
                                                style={{
                                                  marginTop: 10,
                                                  marginLeft: -35,
                                                }}
                                              >
                                                <Form.Check>
                                                  <Form.Check.Label
                                                    style={{
                                                      color: "black",
                                                    }}
                                                  >
                                                    <Form.Check.Input
                                                      name={`nilai_${index}`}
                                                      type="radio"
                                                      value="50"
                                                      onChange={(e) =>
                                                        handleInputNilai(
                                                          index,
                                                          val.kategoriChecklist,
                                                          val.namaChecklist,
                                                          e.target.value
                                                        )
                                                      }
                                                    ></Form.Check.Input>
                                                    <span className=""></span>
                                                    50
                                                  </Form.Check.Label>
                                                </Form.Check>
                                                <Form.Check>
                                                  <Form.Check.Label
                                                    style={{
                                                      color: "black",
                                                    }}
                                                  >
                                                    <Form.Check.Input
                                                      name={`nilai_${index}`}
                                                      type="radio"
                                                      value="75"
                                                      onChange={(e) =>
                                                        handleInputNilai(
                                                          index,
                                                          val.kategoriChecklist,
                                                          val.namaChecklist,
                                                          e.target.value
                                                        )
                                                      }
                                                    ></Form.Check.Input>
                                                    <span className=""></span>
                                                    75
                                                  </Form.Check.Label>
                                                </Form.Check>
                                                <Form.Check>
                                                  <Form.Check.Label
                                                    style={{
                                                      color: "black",
                                                    }}
                                                  >
                                                    <Form.Check.Input
                                                      name={`nilai_${index}`}
                                                      type="radio"
                                                      value="100"
                                                      onChange={(e) =>
                                                        handleInputNilai(
                                                          index,
                                                          val.kategoriChecklist,
                                                          val.namaChecklist,
                                                          e.target.value
                                                        )
                                                      }
                                                    ></Form.Check.Input>
                                                    <span className=""></span>
                                                    100
                                                  </Form.Check.Label>
                                                </Form.Check>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </Card.Body>
                                      </Card>
                                    </Col>
                                  </>
                                );
                              }
                            })}
                          </Row>
                        </Tab>
                        <Tab eventKey="E" title="E. Kebersihan">
                          <Row>
                            {dataChecklist.map((val, index) => {
                              if (val.kategoriChecklist === "E") {
                                return (
                                  <>
                                    <Col sm="6">
                                      <Card>
                                        <Card.Body>
                                          <Row>
                                            <Col>
                                              {val.namaChecklist}

                                              <Row
                                                sm="12"
                                                style={{
                                                  marginTop: 10,
                                                  marginLeft: -35,
                                                }}
                                              >
                                                <Form.Check>
                                                  <Form.Check.Label
                                                    style={{
                                                      color: "black",
                                                    }}
                                                  >
                                                    <Form.Check.Input
                                                      name={`nilai_${index}`}
                                                      type="radio"
                                                      value="50"
                                                      onChange={(e) =>
                                                        handleInputNilai(
                                                          index,
                                                          val.kategoriChecklist,
                                                          val.namaChecklist,
                                                          e.target.value
                                                        )
                                                      }
                                                    ></Form.Check.Input>
                                                    <span className=""></span>
                                                    50
                                                  </Form.Check.Label>
                                                </Form.Check>
                                                <Form.Check>
                                                  <Form.Check.Label
                                                    style={{
                                                      color: "black",
                                                    }}
                                                  >
                                                    <Form.Check.Input
                                                      name={`nilai_${index}`}
                                                      type="radio"
                                                      value="75"
                                                      onChange={(e) =>
                                                        handleInputNilai(
                                                          index,
                                                          val.kategoriChecklist,
                                                          val.namaChecklist,
                                                          e.target.value
                                                        )
                                                      }
                                                    ></Form.Check.Input>
                                                    <span className=""></span>
                                                    75
                                                  </Form.Check.Label>
                                                </Form.Check>
                                                <Form.Check>
                                                  <Form.Check.Label
                                                    style={{
                                                      color: "black",
                                                    }}
                                                  >
                                                    <Form.Check.Input
                                                      name={`nilai_${index}`}
                                                      type="radio"
                                                      value="100"
                                                      onChange={(e) =>
                                                        handleInputNilai(
                                                          index,
                                                          val.kategoriChecklist,
                                                          val.namaChecklist,
                                                          e.target.value
                                                        )
                                                      }
                                                    ></Form.Check.Input>
                                                    <span className=""></span>
                                                    100
                                                  </Form.Check.Label>
                                                </Form.Check>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </Card.Body>
                                      </Card>
                                    </Col>
                                  </>
                                );
                              }
                            })}
                          </Row>
                        </Tab>
                      </Tabs>
                      <Form.Group>
                        <label>Description</label>
                        <Form.Control
                          onChange={(e) => {
                            setDeskripsi(e.target.value);
                            // setProject({
                            //   ...project,
                            //   deskripsi: e.target.value,
                            // });
                          }}
                          // placeholder="Tuliskan Deskripsi Project"
                          as="textarea"
                        ></Form.Control>
                      </Form.Group>
                      <Button
                        className="btn-fill pull-right"
                        // type="submit"
                        variant="info"
                        disabled={
                          client.value === "" ||
                          deskripsi === "" ||
                          namaAnggota === "" ||
                          nopeg === ""
                            ? true
                            : false
                        }
                        style={{
                          backgroundColor:
                            client.value === "" ||
                            deskripsi === "" ||
                            nopeg === "" ||
                            namaAnggota === ""
                              ? "#d3d3d3"
                              : "", // Warna abu-abu ketika dinonaktifkan
                          borderColor:
                            client.value === "" ||
                            deskripsi === "" ||
                            nopeg === "" ||
                            namaAnggota === ""
                              ? "#d3d3d3"
                              : "", // Warna abu-abu ketika dinonaktifkan
                          color:
                            client.value === "" ||
                            deskripsi === "" ||
                            nopeg === "" ||
                            namaAnggota === ""
                              ? "#fff"
                              : "", // Warna teks ketika dinonaktifkan
                          cursor:
                            client.value === "" ||
                            deskripsi === "" ||
                            nopeg === "" ||
                            namaAnggota === ""
                              ? "not-allowed"
                              : "pointer", // Menunjukkan bahwa tombol tidak bisa diklik
                        }}
                        onClick={submitFormChecklist}
                      >
                        Submit
                      </Button>
                    </>
                    {/* )} */}
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReactTables;
