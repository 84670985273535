const initialState = {
  getListChecklist: [],
  getLaporanChecklist: [],
};
const uniformChecklistReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (action.type) {
    case "SET_GETLISTCHECKLIST":
      return {
        ...state,
        getListChecklist: data,
      };
    case "SET_GETLAPORANCHECKLIST":
      return {
        ...state,
        getLaporanChecklist: data,
      };
    default:
      return state;
  }
};
export default uniformChecklistReducer;
