import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import {
  getRuangPatroli,
  deleteRuang,
  daftarruangan,
  downloadPDF,
  editKodeRuangan,
  getAllCabang,
  getCabangAktif,
} from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function ReactTables() {
  const [singleSelect, setSingleSelect] = React.useState("");
  const dispatch = useDispatch();
  const monitoring = useSelector((state) => state.monitoringReducer);
  const cabang = useSelector((state) => state.cabangReducer);
  const auth = useSelector((state) => state.authReducer);
  const [data, setData] = React.useState([]);
  const [dataRuangan, setDataRuangan] = React.useState([]);
  const [dataBaru, setDataBaru] = React.useState({});
  const [modal, setModal] = React.useState(false);
  const [dataGedung, setDataGedung] = React.useState({});
  const [modalRuangan, setModalRuangan] = React.useState(false);
  const [modalEditRuangan, setModalEditRuangan] = React.useState(false);
  const [idRuangan, setIdRuangan] = React.useState("");
  const [kodeRuangan, setKodeRuangan] = React.useState("");
  const [namaEditClient, setNamaClient] = React.useState("");
  const [idCabang, setIdCabang] = React.useState({
    value: "",
    label: "Choose the Client",
    isDisabled: true,
  });
  const [listCabang, setListCabang] = React.useState([]);
  const [namaEditCabang, setNamaEdtCabang] = React.useState("");
  const [idEditCabang, setIdEditCabang] = React.useState({
    value: "",
    label: "Choose the Client",
    isDisabled: true,
  });
  const history = useHistory();

  React.useEffect(() => {
    getRuangPatroli(dispatch);
    getCabangAktif(dispatch);
  }, []);

  React.useEffect(() => {
    let tmp = monitoring.listRuangPatroli.map((val, index) => {
      return {
        ...val,
        namaClient: val.namaClient ? val.namaClient : "-",
        namaCabang: val.namaCabang ? val.namaCabang : "-",
        // actions: (
        //   <div className="actions-right">
        //     <Button
        //       onClick={() => {
        //         setModalEditRuangan(true);
        //         setIdRuangan(val._id);
        //         console.log(val);
        //         setNamaEdtCabang(`${val.namaCabang} - ${val.namaClient}`);
        //         // setNamaClient(val.namaClient);
        //         // downloadPDF(val2._id, val2.area);
        //       }}
        //       variant="warning"
        //       size="md"
        //       className="btn-link remove text-warning"
        //     >
        //       <i className="fa fa-edit fa-lg" />
        //     </Button>
        //     {/* <Button
        //       onClick={() => {
        //         // console.log(val2._id);
        //         downloadPDF(val._id, val.area);
        //       }}
        //       variant="danger"
        //       size="md"
        //       className="btn-link remove text-info"
        //     >
        //       <i className="fa fa-download fa-lg" />
        //     </Button> */}
        //     <Button
        //       onClick={() => {
        //         // console.log(val2._id);
        //         deleteRuang(val._id);
        //       }}
        //       variant="danger"
        //       size="md"
        //       className="btn-link remove text-danger"
        //     >
        //       <i className="fa fa-times fa-lg" />
        //     </Button>
        //   </div>
        // ),
      };
    });
    setDataRuangan(tmp);
  }, [monitoring.listRuangPatroli]);
  React.useEffect(() => {
    let tmp = cabang.getCabangAktif.map((val, index) => {
      // console.log(val, "valll");
      return {
        ...val,
        label: `${val.namaCabang} - ${val.namaClient}`,
        value: val.idCabang,
      };
    });
    setListCabang(tmp);
  }, [cabang.getCabangAktif]);

  // React.useEffect(() => {
  //   let tmp = monitoring.listArea.map((val, index) => {
  //     return {
  //       ...val,
  //       totalRuangan: val.ruangan.length,
  //       actions: (
  //         // we've added some custom button actions
  //         <div className="actions-right">
  //           <Button
  //             onClick={() => {
  //               setDataGedung(val);
  //               setModalRuangan(true);
  //             }}
  //             variant="info"
  //             size="md"
  //             className="btn-link remove text-info"
  //           >
  //             <i className="fa fa-plus-circle fa-lg" />
  //           </Button>{" "}
  //           <Button
  //             onClick={() => {
  //               deleteGedung(val._id);
  //             }}
  //             variant="danger"
  //             size="md"
  //             className="btn-link remove text-danger"
  //           >
  //             <i className="fa fa-times fa-lg" />
  //           </Button>{" "}
  //         </div>
  //       ),
  //     };
  //   });
  //   setData(tmp);
  //   let tmp2 = [];
  //   monitoring.listArea.map((val, index) => {
  //     val.ruangan.forEach((val2, index2) => {
  //       tmp2.push({
  //         gedung: val.gedung,
  //         ruangan: val2.area,
  //         koderuangan: val2.koderuangan,
  //         actions: (
  //           <div className="actions-right">
  //             <Button
  //               onClick={() => {
  //                 setModalEditRuangan(true);
  //                 setIdRuangan(val2._id);
  //                 console.log(val2);
  //                 // downloadPDF(val2._id, val2.area);
  //               }}
  //               variant="warning"
  //               size="md"
  //               className="btn-link remove text-warning"
  //             >
  //               <i className="fa fa-edit fa-lg" />
  //             </Button>
  //             <Button
  //               onClick={() => {
  //                 // console.log(val2._id);
  //                 downloadPDF(val2._id, val2.area);
  //               }}
  //               variant="danger"
  //               size="md"
  //               className="btn-link remove text-info"
  //             >
  //               <i className="fa fa-download fa-lg" />
  //             </Button>
  //             <Button
  //               onClick={() => {
  //                 // console.log(val2._id);
  //                 deleteRuang(val2._id);
  //               }}
  //               variant="danger"
  //               size="md"
  //               className="btn-link remove text-danger"
  //             >
  //               <i className="fa fa-times fa-lg" />
  //             </Button>
  //           </div>
  //         ),
  //       });
  //     });
  //   });
  //   setDataRuangan(tmp2);
  // }, [monitoring.listArea]);

  return (
    <>
      <Container fluid>
        {auth.role === "all" ||
        auth.role === "gsc" ||
        auth.role === "market" ? (
          <>
            <Button
              className="btn-wd mr-1"
              onClick={() => {
                setModalRuangan(true);
                setDataGedung("");
                setIdCabang({
                  value: "",
                  label: "Choose the Client",
                  isDisabled: true,
                });
              }}
              variant="primary"
            >
              Add Building
            </Button>
          </>
        ) : (
          ""
        )}

        <Row>
          <Col md="12">
            <h4 className="title">Area</h4>

            <Card>
              <Card.Body>
                <ReactTable
                  data={dataRuangan}
                  columns={[
                    {
                      Header: "Area",
                      accessor: "area",
                    },
                    {
                      Header: "Area Code",
                      accessor: "koderuangan",
                    },

                    {
                      Header: "Branch Name",
                      accessor: "namaCabang",
                    },
                    {
                      Header: "Client Name",
                      accessor: "namaClient",
                    },
                    // {
                    //   Header: "Actions",
                    //   accessor: "actions",
                    //   sortable: false,
                    //   filterable: false,
                    // },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        size="lg"
        show={modalRuangan}
        onHide={() => {
          setModalRuangan(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Add Area</Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Client's Name</label>
                          <Select
                            className="react-select primary pb-2"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            onChange={(value) => {
                              setIdCabang(value);
                            }}
                            value={idCabang}
                            options={[
                              {
                                value: "",
                                label: "Choose the Client",
                                isDisabled: true,
                              },
                              ...listCabang,
                              "-",
                            ]}
                            placeholder="Single Select"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label>Area's Name</label>
                          <Form.Control
                            onChange={(e) => {
                              setDataGedung({
                                ...dataGedung,
                                namaRuangan: e.target.value,
                              });
                            }}
                            placeholder="Masukan Nama Area"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={(e) => {
                        e.preventDefault();
                        daftarruangan({
                          area: dataGedung.namaRuangan,
                          idCabang: idCabang.idCabang,
                        });
                      }}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={modalEditRuangan}
        onHide={() => {
          setModalEditRuangan(false);
        }}
        onClick={() => {}}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Edit</Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Client's Name</label>
                          <Select
                            className="react-select primary pb-2"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            onChange={(value) => {
                              setIdEditCabang(value);
                            }}
                            value={idEditCabang}
                            options={[
                              {
                                value: "",
                                label: `${namaEditCabang} `,
                                isDisabled: true,
                              },
                              ...listCabang,
                              "-",
                            ]}
                            placeholder="Single Select"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label>Area Code</label>
                          <Form.Control
                            // value={tampilKodeRuang}
                            onChange={(e) => {
                              setKodeRuangan(e.target.value);
                            }}
                            placeholder="Masukan Kode Ruangan Baru"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={(e) => {
                        e.preventDefault();
                        console.log(idRuangan, idEditCabang, kodeRuangan);
                        // editKodeRuangan({
                        //   _id: idRuangan,
                        //   kodeRuangan: kodeRuangan,
                        //   idCabang: idEditCabang.idCabang,
                        // });
                        // daftarlokasi(dataBaru);
                      }}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReactTables;
