const initialState = {
  getVisitReview: [],
  grafikCsVisit: null,
};
const csVisitReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (action.type) {
    case "SET_GETCSVISIT":
      return {
        ...state,
        getVisitReview: data,
      };
    case "SET_GRAFIKCSVISIT":
      return {
        ...state,
        grafikCsVisit: data,
      };

    default:
      return state;
  }
};
export default csVisitReducer;
