import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";

import "react-vertical-timeline-component/style.min.css";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import DataTable from "react-data-table-component";

import {
  createList,
  createReport,
  getTraining,
  editTraining,
  kirimReportTraining,
  getCabangAktif,
  getReportIncident,
  updateIncidentReport,
  getList,
} from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import Swal from "sweetalert2";
import { compose } from "redux";

function ReactTables() {
  const dispatch = useDispatch();
  const date = new Date();
  const uniformChecklist = useSelector(
    (state) => state.uniformChecklistReducer
  );
  const incident = useSelector((state) => state.incidentReducer);
  const cabang = useSelector((state) => state.cabangReducer);
  const [modal, setModal] = React.useState(false);
  const [modalChecklist, setModalChecklist] = React.useState(false);
  const [modalEditTraining, setModalEditTraining] = React.useState(false);
  const [modalTrainingApprove, setModalTrainingApprove] = React.useState(false);
  const [dataIncident, setDataIncident] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const [listName, setListName] = React.useState("");
  const [jenis, setJenis] = React.useState("");
  const [trainingId, setTrainingId] = React.useState("");
  const [deskripsiTraining, setDeskripsiTraining] = React.useState("");
  const [noteTraining, setNoteTraining] = React.useState("");
  const [dariTgl, setDariTgl] = React.useState(
    // new Date(date.getFullYear(), date.getMonth(), 1).setHours(0, 0, 0, 0)
    new Date()
  );
  const [modalPreviewFoto, setModalPreviewFoto] = React.useState(false);
  const [smpTgl, setSmpTgl] = React.useState(new Date().setHours(23, 59, 0, 0));
  const [gambar, setGambar] = React.useState([]);
  const [kategoriKejadian, setKategroiKejadian] = React.useState({
    value: "",
    label: "Choose the Category",
    isDisabled: true,
  });
  const respon = "Done";
  const [client, setClient] = React.useState({
    value: "",
    label: "Choose the Client",
    isDisabled: true,
  });
  const [type, setType] = React.useState({
    value: "",
    label: "Choose the Type of Incident",
    isDisabled: true,
  });
  const [kategori, setKategori] = React.useState({
    value: "",
    label: "Choose the Category",
    isDisabled: true,
  });
  const [listCabang, setListCabang] = React.useState([]);
  const [judul, setJudul] = React.useState("");
  const [dropMajor, setDropMajor] = React.useState(false);
  const [dropNonMajor, setDropNonMajor] = React.useState(false);
  let tanggalSekarang = new Date();

  const handleTypeChange = (selectedOption) => {
    setType(selectedOption);
    // Reset state sebelum mengatur kondisi baru
    setDropMajor(false);
    setDropNonMajor(false);

    if (selectedOption.value === "Major") {
      setDropMajor(true);
    } else if (selectedOption.value === "Non Major") {
      setDropNonMajor(true);
    }
  };

  function submitList(e) {
    e.preventDefault();
    console.log(listName, kategori.value);
    if (!kategori.value || !listName) {
      Swal.fire({
        title: "Form data wajib diisi",
        icon: "error",
      });
    } else {
      createList({
        kategoriChecklist: kategori.value,
        namaChecklist: listName,
      });
    }
  }

  function handleOpenModal(e) {
    setKategori({
      value: "",
      label: "Choose the Client",
      isDisabled: true,
    });
    setListName("");
  }
  React.useEffect(() => {
    getCabangAktif(dispatch);
    // getReportIncident(dispatch);
    getList(dispatch);
  }, []);
  function submitReportTraining(e) {
    e.preventDefault();
    kirimReportTraining({ noteTraining, gambar, trainingId, respon });
  }

  function edit(e) {
    e.preventDefault();
    editTraining({
      trainingId: trainingId,
      startDate: new Date(dariTgl).toISOString(),
      deadlineDate: new Date(smpTgl).toISOString(),
    });
  }
  React.useEffect(() => {
    let tmp = cabang.getCabangAktif.map((val, index) => {
      return {
        ...val,
        label: `${val.namaCabang} - ${val.namaClient}`,
        value: val.idCabang,
      };
    });
    setListCabang(tmp);
  }, [cabang.getCabangAktif]);
  React.useEffect(() => {
    let tmp = uniformChecklist.getListChecklist.map((val, index) => {
      return {
        ...val,
        kategori:
          val.kategoriChecklist === "A"
            ? "Posisi dan Keberadaan"
            : val.kategoriChecklist === "B"
            ? "Administrasi"
            : val.kategoriChecklist === "C"
            ? "Penampilan"
            : val.kategoriChecklist === "D"
            ? "Keahlian"
            : "Kebersihan",
      };
    });
    let kelompokKategori = tmp.reduce((acc, val) => {
      if (!acc[val.kategoriChecklist]) {
        acc[val.kategoriChecklist] = [];
      }
      acc[val.kategoriChecklist].push(val.namaChecklist); // Assuming 'namaChecklist' is the field name
      return acc;
    }, {});
    let formattedData = Object.entries(kelompokKategori).map(
      ([kategori, namaChecklist]) => {
        return { kategori, namaChecklist };
      }
    );

    setData(formattedData);
  }, [uniformChecklist.getListChecklist]);
  const history = useHistory();

  React.useEffect(() => {
    getTraining(dispatch);
  }, []);
  const customStyles = {
    rows: {
      style: {
        height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        // "&:first-child": {
        //   borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        // },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        // "&:first-child": {
        //   borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        // },
      },
    },
  };
  const columns = [
    {
      name: "Category Checklist",
      width: "200px",
      sortable: true,
      selector: (row) =>
        row.kategori === "A"
          ? "A. Posisi dan Keberadaan"
          : row.kategori === "B"
          ? "B. Administrasi"
          : row.kategori === "C"
          ? "C. Penampilan"
          : row.kategori === "D"
          ? "D. Keahlian"
          : "E. Kebersihan",
    },
  ];
  const ExpandedComponent1 = ({ data }) => (
    <>
      <ul style={{ marginTop: 10 }}>
        <h5 style={{ marginBottom: 0, fontWeight: "bold" }}>
          List Checklist :
        </h5>
        {data.namaChecklist.map((item, index) => (
          <li style={{ fontSize: 15 }} key={index}>
            {item}
          </li>
        ))}
      </ul>
    </>
  );
  return (
    <>
      <div
        id="previewImage"
        style={{
          display: modalPreviewFoto ? "block" : "none",
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          zIndex: "9999",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={modalPreviewFoto}
          alt="Preview"
          style={{
            borderRadius: "5px",
            boxShadow: "4px 4px 8px 0px rgba(0,0,0,0.75)",
            maxHeight: "80%",
            maxWidth: "80%",
            objectFit: "contain",
            background: "transparent",
            position: "absolute",
            top: "50%", // Posisi vertikal ke tengah
            left: "50%", // Posisi horizontal ke tengah
            transform: "translate(-50%, -50%)",
          }}
        />
        <button
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            fontSize: "20px",
            cursor: "pointer",
          }}
          onClick={() => setModalPreviewFoto("")}
        >
          &times;
        </button>
      </div>
      <Container fluid>
        {/* <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModal(true);
          }}
          variant="primary"
        >
          Pengajuan Project
        </Button> */}
        <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModalChecklist(true);
            handleOpenModal();
          }}
          variant="primary"
        >
          Add List
        </Button>
        <Row>
          <Col md="12">
            <h4 className="title">List Checklist</h4>
            <Card>
              <Card.Body>
                {/* <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "List Name",
                      accessor: "namaChecklist",
                    },
                    {
                      Header: "Category",
                      accessor: "kategori",
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                /> */}
                <DataTable
                  columns={columns}
                  data={data}
                  expandableRows
                  customStyles={customStyles}
                  expandableRowsComponent={ExpandedComponent1}
                  pagination
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal
        size="lg"
        show={modalChecklist}
        onHide={() => {
          setModalChecklist(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Title as="h4">Add Checklist</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Category</label>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            value={kategori}
                            onChange={(val) => setKategori(val)}
                            options={[
                              {
                                value: "",
                                label: "Choose the Category",
                                isDisabled: true,
                              },
                              {
                                value: "A",
                                label: "A. Posisi dan Keberadaan",
                              },
                              {
                                value: "B",
                                label: "B. Administrasi",
                              },
                              {
                                value: "C",
                                label: "C. Penampilan",
                              },
                              {
                                value: "D",
                                label: "D. Keahlian",
                              },
                              {
                                value: "E",
                                label: "E. Kebershan",
                              },
                            ]}
                          />
                        </Form.Group>
                        <Form.Group>
                          <label>List Name</label>
                          <Form.Control
                            onChange={(e) => {
                              setListName(e.target.value);
                            }}
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>

                    <div className="clearfix"></div>
                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submitList}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReactTables;
