import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import moment from "moment";

export function createExternalCoordination(data) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEnterKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .post("coordination/createcoordination", data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      });
    })
    .then(() => {
      window.location.reload();
    })
    .catch(errorHandler);
}

export function getCoordination(dispacth, data) {
  baseAxios
    .get("/coordination/getcoordination", {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispacth({ type: "SET_GETCOORDINATION", data: respon.data.data });
    });
}

export function createBriefing(data) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEnterKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .post("coordination/createbriefing", data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      });
    })
    .then(() => {
      window.location.reload();
    })
    .catch(errorHandler);
}

export function getBriefing(dispacth, data) {
  baseAxios
    .get("/coordination/getbriefing", {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispacth({ type: "SET_GETBRIEFING", data: respon.data.data });
    });
}
