import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import {
  createExternalCoordination,
  getCabangAktif,
  getCoordination,
} from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import Swal from "sweetalert2";
import { compose } from "redux";

function ReactTables() {
  const dispatch = useDispatch();
  const date = new Date();
  const coordination = useSelector((state) => state.coordinationReducer);
  const cabang = useSelector((state) => state.cabangReducer);
  const [modal, setModal] = React.useState(false);
  const [modalTraining, setModalTraining] = React.useState(false);
  const [dataCoordination, setCoordination] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [status, setStatus] = React.useState("");

  const respon = "Done";
  const [client, setClient] = React.useState({
    value: "",
    label: "Choose the Client",
    isDisabled: true,
  });

  const [listCabang, setListCabang] = React.useState([]);
  const [judul, setJudul] = React.useState("");
  const [dropMajor, setDropMajor] = React.useState(false);
  const [dropNonMajor, setDropNonMajor] = React.useState(false);
  let tanggalSekarang = new Date();
  const [dariTgl, setDariTgl] = React.useState(new Date().setHours(0, 0, 0, 0));
  const [modalPreviewFoto, setModalPreviewFoto] = React.useState(false);
  const [jamMulai, setJamMulai] = React.useState(new Date());
  const [jamSelesai, setJamSelesai] = React.useState(new Date());
  const [gambar, setGambar] = React.useState([]);
  const [namaPelaksana, setNamaPelaksana] = React.useState("");
  const [jabatan, setJabatan] = React.useState("");
  const [namaEksternal, setnamaEksternal] = React.useState("");
  const [namaPenjaga, setNamaPenjaga] = React.useState("");

  function submitExternalCoordination(e) {
    e.preventDefault();
    if (
      !client.idClient ||
      !client.idCabang ||
      gambar.length === 0 ||
      !judul ||
      !namaPelaksana ||
      !jabatan ||
      !judul ||
      !namaEksternal ||
      !namaPenjaga
    ) {
      Swal.fire({
        title: "Form data wajib diisi",
        icon: "error",
      });
    } else {
      let fd = new FormData();
      fd.append("idCabang", client.idCabang);
      fd.append("idClient", client.idClient);
      fd.append("namaCabang", client.namaCabang);
      fd.append("namaClient", client.namaClient);
      fd.append("jamMulai", new Date(jamMulai).toISOString());
      fd.append("jamSelesai", new Date(jamSelesai).toISOString());
      fd.append("namaPelaksana", namaPelaksana);
      fd.append("jabatan", jabatan);
      fd.append("judul", judul);
      fd.append("namaEksternal", namaEksternal);
      fd.append("namaPenjaga", namaPenjaga);
      fd.append("tanggal", new Date(dariTgl).toISOString());
      gambar.map((val, index) => [fd.append("dokumentasiAwalArr", val)]);
      // file.map((val, index) => [fd.append("fileMonthly", val)]);
      createExternalCoordination(fd);
    }
  }

  function handleOpenModal(e) {
    setClient({
      value: "",
      label: "Choose the Client",
      isDisabled: true,
    });
    setGambar([]);
    setDariTgl(new Date());
    setJamMulai(new Date());
    setJamSelesai(new Date());
  }
  React.useEffect(() => {
    getCabangAktif(dispatch);
    getCoordination(dispatch);
  }, []);

  React.useEffect(() => {
    let tmp = cabang.getCabangAktif.map((val, index) => {
      return {
        ...val,
        label: `${val.namaCabang} - ${val.namaClient}`,
        value: val.idCabang,
      };
    });
    setListCabang(tmp);
  }, [cabang.getCabangAktif]);
  React.useEffect(() => {
    let tmp = coordination.getCoordination.map((val, index) => {
      return {
        ...val,
        tanggal: moment(val.tanggal).format("DD MMM YYYY"),
        actions: (
          <div className="actions-right">
            <Button
              onClick={() => {
                setModal(true);
                setCoordination(val);
              }}
              variant="danger"
              size="md"
              className="btn-link remove text-success"
            >
              <i className="fa fa-info-circle fa-lg" />
            </Button>{" "}
          </div>
        ),
      };
    });
    setData(tmp);
  }, [coordination.getCoordination]);

  const history = useHistory();

  return (
    <>
      <div
        id="previewImage"
        style={{
          display: modalPreviewFoto ? "block" : "none",
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          zIndex: "9999",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={modalPreviewFoto}
          alt="Preview"
          style={{
            borderRadius: "5px",
            boxShadow: "4px 4px 8px 0px rgba(0,0,0,0.75)",
            maxHeight: "80%",
            maxWidth: "80%",
            objectFit: "contain",
            background: "transparent",
            position: "absolute",
            top: "50%", // Posisi vertikal ke tengah
            left: "50%", // Posisi horizontal ke tengah
            transform: "translate(-50%, -50%)",
          }}
        />
        <button
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            fontSize: "20px",
            cursor: "pointer",
          }}
          onClick={() => setModalPreviewFoto("")}
        >
          &times;
        </button>
      </div>
      <Container fluid>
        {/* <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModal(true);
          }}
          variant="primary"
        >
          Pengajuan Project
        </Button> */}
        <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModalTraining(true);
            handleOpenModal();
          }}
          variant="primary"
        >
          Add External Coordination
        </Button>
        <Row>
          <Col md="12">
            <h4 className="title">External Coordination Recapitulation</h4>
            <Card>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Submitter",
                      accessor: "dilaporkanOleh",
                    },
                    {
                      Header: "Date",
                      accessor: "tanggal",
                    },
                    {
                      Header: "Time",
                      accessor: "waktu",
                    },

                    {
                      Header: "Officer Name",
                      accessor: "namaPelaksana",
                    },
                    {
                      Header: "Position",
                      accessor: "jabatan",
                    },
                    {
                      Header: "External Name",
                      accessor: "namaEksternal",
                    },
                    {
                      Header: "Personnel Guard",
                      accessor: "namaPenjaga",
                    },
                    {
                      Header: "Branch Name",
                      accessor: "namaCabang",
                    },
                    {
                      Header: "Client Name",
                      accessor: "namaClient",
                    },

                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
          setStatus("");
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Detail
          </Modal.Title>
        </Modal.Header>
        <div style={{ maxHeight: "800px", overflowY: "scroll" }}>
          <Modal.Body>
            <Row>
              <Col md="12">
                <Form action="" className="form" method="">
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Submitter
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataCoordination.dilaporkanOleh}</Col>
                      </Row>

                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Date
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">
                          {moment(dataCoordination.tanggal).format(
                            "DD MMM YYYY"
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Time
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataCoordination.waktu}</Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Officer Name
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataCoordination.namaPelaksana}</Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Position
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataCoordination.jabatan}</Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          External Name
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataCoordination.namaEksternal}</Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Personnel Guard
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataCoordination.namaPenjaga}</Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Branch
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataCoordination.namaCabang}</Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Client
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataCoordination.namaClient}</Col>
                      </Row>

                      {/* <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          File Monthly Report
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">
                          {dataCoordination.fileMonthly?.map((val, index) => {
                            return (
                              <>
                                <div className="clearfix"></div>
                                <Button
                                  className="btn-fill pull-right btn-sm"
                                  type="submit"
                                  variant="info"
                                  href={val}
                                >
                                  <i
                                    className="fa fa-download"
                                    aria-hidden="true"
                                    style={{ marginRight: 5 }}
                                  ></i>
                                  Download File
                                </Button>
                                <div className="clearfix"></div>
                              </>
                            );
                          })}
                        </Col>
                      </Row> */}

                      <Row style={{ marginTop: 40 }}>
                        <Col
                          sm="12"
                          style={{
                            fontWeight: "bold",
                            textAlign: "center",
                            textTransform: "uppercase",
                          }}
                        >
                          Documentation
                        </Col>
                      </Row>
                      <Row>
                        {!dataCoordination.dokumentasiAwalArr ||
                        dataCoordination.dokumentasiAwalArr.length == 0 ? (
                          <Col sm="12" style={{ textAlign: "center" }}>
                            Final Documentation is Not Available
                          </Col>
                        ) : (
                          dataCoordination.dokumentasiAwalArr.map(
                            (val, index) => {
                              return (
                                <>
                                  <Col sm="4">
                                    <Image
                                      src={val}
                                      style={{
                                        width: 200,
                                        height: 250,
                                        margin: 20,
                                      }}
                                      thumbnail
                                      onClick={() => {
                                        setModalPreviewFoto(val);
                                      }}
                                    />
                                  </Col>
                                </>
                              );
                            }
                          )
                        )}
                      </Row>
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Modal.Body>
        </div>
      </Modal>

      <Modal
        size="lg"
        show={modalTraining}
        onHide={() => {
          setModalTraining(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Title as="h4">Add External Coordination</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Form.Group>
                      <label>Title</label>
                      <Form.Control
                        onChange={(e) => {
                          setJudul(e.target.value);
                          // setProject({
                          //   ...project,
                          //   deskripsi: e.target.value,
                          // });
                        }}
                        // placeholder="Tuliskan Deskripsi Project"
                        type="text"
                      ></Form.Control>
                    </Form.Group>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Client's Name</label>
                          <Select
                            className="react-select primary pb-2"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            onChange={(value) => {
                              setClient(value);
                            }}
                            value={client}
                            options={[
                              {
                                value: "",
                                label: "Choose the Client",
                                isDisabled: true,
                              },
                              ...listCabang,
                              "-",
                            ]}
                            placeholder="Single Select"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label>Date</label>
                        <Form.Group>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                            }}
                            onChange={(e) => {
                              setDariTgl(e.toDate());
                            }}
                            value={dariTgl}
                            initialValue={dariTgl}
                            timeFormat={false}
                          ></ReactDatetime>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        <label>Start Time</label>
                        <Form.Group>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                            }}
                            onChange={(e) => {
                              setJamMulai(e.toDate());
                            }}
                            value={jamMulai}
                            initialValue={jamMulai}
                            dateFormat={false}
                            timeFormat="HH:mm"
                          ></ReactDatetime>
                        </Form.Group>
                      </Col>
                      <Col sm="6">
                        <label>End Time</label>
                        <Form.Group>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                            }}
                            onChange={(e) => {
                              setJamSelesai(e.toDate());
                            }}
                            value={jamSelesai}
                            initialValue={jamSelesai}
                            dateFormat={false}
                            timeFormat="HH:mm"
                          ></ReactDatetime>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group>
                      <label>Officer Name</label>
                      <Form.Control
                        onChange={(e) => {
                          setNamaPelaksana(e.target.value);
                          // setProject({
                          //   ...project,
                          //   deskripsi: e.target.value,
                          // });
                        }}
                        // placeholder="Tuliskan Deskripsi Project"
                        type="text"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group>
                      <label>Position</label>
                      <Form.Control
                        onChange={(e) => {
                          setJabatan(e.target.value);
                          // setProject({
                          //   ...project,
                          //   deskripsi: e.target.value,
                          // });
                        }}
                        // placeholder="Tuliskan Deskripsi Project"
                        type="text"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group>
                      <label>External Name</label>
                      <Form.Control
                        onChange={(e) => {
                          setnamaEksternal(e.target.value);
                          // setProject({
                          //   ...project,
                          //   deskripsi: e.target.value,
                          // });
                        }}
                        // placeholder="Tuliskan Deskripsi Project"
                        type="text"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group>
                      <label>Personnel Guard</label>
                      <Form.Control
                        onChange={(e) => {
                          setNamaPenjaga(e.target.value);
                          // setProject({
                          //   ...project,
                          //   deskripsi: e.target.value,
                          // });
                        }}
                        // placeholder="Tuliskan Deskripsi Project"
                        as="textarea"
                      ></Form.Control>
                    </Form.Group>

                    <label>Documentation</label>
                    <br />
                    {gambar.length === 0 ? (
                      <>
                        <label
                          className="label-input-file btn btn-danger text-white mb-4"
                          for="import2"
                        >
                          Add Photo
                          <input
                            type="file"
                            id="import2"
                            onClick={(e) => {
                              e.target.value = "";
                            }}
                            onChange={(e) => {
                              setGambar([
                                ...gambar,
                                ...Array.from(e.target.files),
                              ]);
                            }}
                            style={{ display: "none" }}
                            accept="image/*"
                            multiple
                          />
                        </label>
                      </>
                    ) : null}
                    {gambar.map((val, index) => {
                      return (
                        <div className="d-flex align-items-start">
                          <p>{val.name}</p>
                          <Button
                            onClick={() => {
                              setGambar([
                                ...gambar.slice(0, index),
                                ...gambar.slice(index + 1, gambar.length),
                              ]);
                            }}
                            variant="danger"
                            size="sm"
                            className="btn-link remove text-danger"
                          >
                            <i className="fa fa-times" />
                          </Button>
                        </div>
                      );
                    })}
                    <div className="clearfix"></div>
                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submitExternalCoordination}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReactTables;
