const initialState = {
  getCoordination: [],
  getBriefing: [],
};
const coordinationReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (action.type) {
    case "SET_GETCOORDINATION":
      return {
        ...state,
        getCoordination: data,
      };

    case "SET_GETBRIEFING":
      return {
        ...state,
        getBriefing: data,
      };
    default:
      return state;
  }
};
export default coordinationReducer;
