import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import {
  createCsVisit,
  getTraining,
  getCabangAktif,
  getMonthlyReport,
  getCsVisit,
} from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import Swal from "sweetalert2";
import { compose } from "redux";

function ReactTables() {
  const dispatch = useDispatch();
  const date = new Date();
  const csVisit = useSelector((state) => state.csVisitReducer);
  const cabang = useSelector((state) => state.cabangReducer);
  const [modal, setModal] = React.useState(false);
  const [modalTraining, setModalTraining] = React.useState(false);
  const [dataReport, setDataReport] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const [deskripsi, setDeskripsi] = React.useState("");
  const [dariTgl, setDariTgl] = React.useState(new Date().setHours(0, 0, 0, 0));
  const [modalPreviewFoto, setModalPreviewFoto] = React.useState(false);
  const [gambar, setGambar] = React.useState([]);
  const [client, setClient] = React.useState({
    value: "",
    label: "Choose the Client",
    isDisabled: true,
  });

  const [listCabang, setListCabang] = React.useState([]);
  const [temuan, setTemuan] = React.useState([""]);
  let tanggalSekarang = new Date();

  const handleAddColom = () => {
    setTemuan([...temuan, ""]);
  };

  const handleChange = (index, event) => {
    const colomBaru = [...temuan];
    colomBaru[index] = event.target.value;
    setTemuan(colomBaru);
  };
  const handleRemoveField = (index) => {
    const colomBaru = temuan.filter((_, i) => i !== index);
    setTemuan(colomBaru);
  };
  function submitCsVisit(e) {
    e.preventDefault();
    if (!client.idClient || !client.idCabang || !temuan) {
      Swal.fire({
        title: "Form data wajib diisi",
        icon: "error",
      });
    } else {
      // for (let i = 0; i < file.length; i++) {
      //   if (file[i].size > maxFileSize) {
      //     Swal.fire({
      //       title: "Ukuran file terlalu besar, ukuran maksimum 4MB!!!",
      //       icon: "error",
      //     });
      //     return;
      //   }
      // }

      let idCabang = client.idCabang;
      // let fd = new FormData();
      // fd.append("idCabang", idCabang);
      // fd.append("idClient", client.idClient);
      // fd.append("namaCabang", client.namaCabang);
      // fd.append("namaClient", client.namaClient);
      // // fd.append("temuan", JSON.stringify(temuan));
      // fd.append("tanggal", new Date(dariTgl).toISOString());
      createCsVisit({
        idCabang: idCabang,
        idClient: client.idClient,
        namaCabang: client.namaCabang,
        namaClient: client.namaClient,
        tanggal: new Date(dariTgl).toISOString(),
        temuan: JSON.stringify(temuan),
      });
    }
  }

  function handleOpenModal(e) {
    setClient({
      value: "",
      label: "Choose the Client",
      isDisabled: true,
    });
    setGambar([]);
    setDariTgl(new Date());
  }
  React.useEffect(() => {
    getCabangAktif(dispatch);
    getCsVisit(dispatch);
  }, []);

  React.useEffect(() => {
    let tmp = cabang.getCabangAktif.map((val, index) => {
      return {
        ...val,
        label: `${val.namaCabang} - ${val.namaClient}`,
        value: val.idCabang,
      };
    });
    setListCabang(tmp);
  }, [cabang.getCabangAktif]);
  React.useEffect(() => {
    let tmp = csVisit.getVisitReview.map((val, index) => {
      return {
        ...val,
        tanggal: moment(val.tanggal).format("DD MMM YYYY"),
        actions: (
          <div className="actions-right">
            <Button
              onClick={() => {
                setModal(true);
                setDataReport(val);
              }}
              variant="danger"
              size="md"
              className="btn-link remove text-success"
            >
              <i className="fa fa-info-circle fa-lg" />
            </Button>{" "}
          </div>
        ),
      };
    });
    setData(tmp);
  }, [csVisit.getVisitReview]);

  const history = useHistory();

  React.useEffect(() => {
    getTraining(dispatch);
  }, []);

  return (
    <>
      <style jsx>{`
        .btn-icon-hover {
          transition: transform 0.3s ease, color 0.3s ease;
        }

        .btn-icon-hover:hover {
          transform: scale(1.2); /* Membesarkan ikon */
          color: #17a2b8; /* Mengubah warna ikon */
        }

        .btn-icon-hover i {
          transition: color 0.3s ease; /* Animasi perubahan warna */
        }

        .btn-icon-hover:hover i {
          color: #0056b3; /* Mengubah warna ikon saat hover */
        }
      `}</style>
      ;
      <div
        id="previewImage"
        style={{
          display: modalPreviewFoto ? "block" : "none",
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          zIndex: "9999",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={modalPreviewFoto}
          alt="Preview"
          style={{
            borderRadius: "5px",
            boxShadow: "4px 4px 8px 0px rgba(0,0,0,0.75)",
            maxHeight: "80%",
            maxWidth: "80%",
            objectFit: "contain",
            background: "transparent",
            position: "absolute",
            top: "50%", // Posisi vertikal ke tengah
            left: "50%", // Posisi horizontal ke tengah
            transform: "translate(-50%, -50%)",
          }}
        />
        <button
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            fontSize: "20px",
            cursor: "pointer",
          }}
          onClick={() => setModalPreviewFoto("")}
        >
          &times;
        </button>
      </div>
      <Container fluid>
        {/* <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModal(true);
          }}
          variant="primary"
        >
          Pengajuan Project
        </Button> */}
        <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModalTraining(true);
            handleOpenModal();
          }}
          variant="primary"
        >
          Add Findings
        </Button>
        <Row>
          <Col md="12">
            <h4 className="title">CS Visit & Findings Recapitulation</h4>
            <Card>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Submitter",
                      accessor: "dilaporkanOleh",
                    },
                    {
                      Header: "Date",
                      accessor: "tanggal",
                    },
                    {
                      Header: "Total Issues",
                      accessor: "jumlahTemuan",
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                    },

                    {
                      Header: "Branch Name",
                      accessor: "namaCabang",
                    },
                    {
                      Header: "Client Name",
                      accessor: "namaClient",
                    },

                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
          setStatus("");
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Detail
          </Modal.Title>
        </Modal.Header>
        <div style={{ maxHeight: "800px", overflowY: "scroll" }}>
          <Modal.Body>
            {(dataReport.History &&
              dataReport.History[0].statusKejadian === "Initial Report") ||
            (dataReport.History &&
              dataReport.History[0].statusKejadian === "Follow-Up") ? (
              <>
                <Row>
                  <Col md="12">
                    <Card>
                      <Card.Body>
                        <h5 style={{ marginTop: 0 }}>Event Action</h5>
                        <label>Action Date</label>
                        <Form.Group>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                            }}
                            onChange={(e) => {
                              setDariTgl(e.toDate());
                            }}
                            value={dariTgl}
                            initialValue={dariTgl}
                            // isValidDate={validDate}
                            timeFormat={true}
                          ></ReactDatetime>
                        </Form.Group>
                        <Form.Group>
                          <label>Action</label>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            value={tindakan}
                            onChange={(val) => setTindakan(val)}
                            options={[
                              {
                                value: "",
                                label: "Choose the Action",
                                isDisabled: true,
                              },
                              {
                                value: "Follow-Up",
                                label: "Follow-Up",
                              },
                              { value: "Closed", label: "Closed" },
                            ]}
                            placeholder="-Choose-"
                          />
                        </Form.Group>
                        <Form.Group>
                          <label>Description</label>
                          <Form.Control
                            onChange={(e) => {
                              setDeskripsi(e.target.value);
                              // setProject({
                              //   ...project,
                              //   deskripsi: e.target.value,
                              // });
                            }}
                            // placeholder="Tuliskan Deskripsi Project"
                            as="textarea"
                          ></Form.Control>
                        </Form.Group>
                        <label
                          className="label-input-file btn btn-danger text-white mb-4"
                          for="import2"
                        >
                          Add Photo
                          <input
                            type="file"
                            id="import2"
                            onClick={(e) => {
                              e.target.value = "";
                            }}
                            onChange={(e) => {
                              setGambar([
                                ...gambar,
                                ...Array.from(e.target.files),
                              ]);
                            }}
                            style={{ display: "none" }}
                            accept="image/*"
                            multiple
                          />
                        </label>
                        {gambar.map((val, index) => {
                          return (
                            <div className="d-flex align-items-start">
                              <p>{val.name}</p>
                              <Button
                                onClick={() => {
                                  setGambar([
                                    ...gambar.slice(0, index),
                                    ...gambar.slice(index + 1, gambar.length),
                                  ]);
                                }}
                                variant="danger"
                                size="sm"
                                className="btn-link remove text-danger"
                              >
                                <i className="fa fa-times" />
                              </Button>
                            </div>
                          );
                        })}
                        <p>
                          <Button
                            className="btn-fill pull-right"
                            type="submit"
                            variant="info"
                            onClick={updateIncident}
                          >
                            Submit
                          </Button>
                        </p>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : null}
            <Row>
              <Col md="12">
                <Form action="" className="form" method="">
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Submitter
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataReport.dilaporkanOleh}</Col>
                      </Row>

                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Reported Date
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">
                          {moment(dataReport.solvedDate).format("DD MMM YYYY")}
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Total Issues
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataReport.jumlahTemuan}</Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Status
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataReport.status}</Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Branch
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataReport.namaCabang}</Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Client
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataReport.namaClient}</Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Issues
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">
                          {/* <ul> */}
                          {dataReport.temuan?.map((val, index) => {
                            return (
                              <li style={{ fontSize: 15 }} key={index}>
                                {val}
                              </li>
                            );
                          })}
                          {/* </ul> */}
                        </Col>
                      </Row>
                      {/* <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          File Monthly Report
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">
                          {dataReport.fileMonthly?.map((val, index) => {
                            return (
                              <>
                                <div className="clearfix"></div>
                                <Button
                                  className="btn-fill pull-right btn-sm"
                                  type="submit"
                                  variant="info"
                                  href={val}
                                >
                                  <i
                                    className="fa fa-download"
                                    aria-hidden="true"
                                    style={{ marginRight: 5 }}
                                  ></i>
                                  Download File
                                </Button>
                                <div className="clearfix"></div>
                              </>
                            );
                          })}
                        </Col>
                      </Row> */}

                      {/* <Row style={{ marginTop: 40 }}>
                        <Col
                          sm="12"
                          style={{
                            fontWeight: "bold",
                            textAlign: "center",
                            textTransform: "uppercase",
                          }}
                        >
                          Documentation
                        </Col>
                      </Row>
                      <Row>
                        {!dataReport.buktiLaporan ||
                        dataReport.buktiLaporan.length == 0 ? (
                          <Col sm="12" style={{ textAlign: "center" }}>
                            Final Documentation is Not Available
                          </Col>
                        ) : (
                          dataReport.buktiLaporan.map((val, index) => {
                            return (
                              <>
                                <Col sm="4">
                                  <Image
                                    src={val}
                                    style={{
                                      width: 200,
                                      height: 250,
                                      margin: 20,
                                    }}
                                    thumbnail
                                    onClick={() => {
                                      setModalPreviewFoto(val);
                                    }}
                                  />
                                </Col>
                              </>
                            );
                          })
                        )}
                      </Row> */}
                      {/* <Row>
                        {!dataReport.dokumentasi ||
                        dataReport.dokumentasi.length == 0 ? (
                          <Col sm="12" style={{ textAlign: "center" }}>
                            Final Documentation is Not Available
                          </Col>
                        ) : (
                          dataReport.dokumentasi.map((val, index) => {
                            return (
                              <>
                                <Col sm="4">
                                  <Image
                                    src={val}
                                    style={{
                                      width: 200,
                                      height: 250,
                                      margin: 20,
                                    }}
                                    thumbnail
                                    onClick={() => {
                                      setModalPreviewFoto(val);
                                    }}
                                  />
                                </Col>
                              </>
                            );
                          })
                        )}
                      </Row> */}
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Modal.Body>
        </div>
      </Modal>
      <Modal
        size="lg"
        show={modalTraining}
        onHide={() => {
          setModalTraining(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Title as="h4">Add Findings</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Client's Name</label>
                          <Select
                            className="react-select primary pb-2"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            onChange={(value) => {
                              setClient(value);
                            }}
                            value={client}
                            options={[
                              {
                                value: "",
                                label: "Choose the Client",
                                isDisabled: true,
                              },
                              ...listCabang,
                              "-",
                            ]}
                            placeholder="Single Select"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label>Date</label>
                        <Form.Group>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                            }}
                            onChange={(e) => {
                              setDariTgl(e.toDate());
                            }}
                            value={dariTgl}
                            initialValue={dariTgl}
                            timeFormat={false}
                          ></ReactDatetime>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="align-items-center">
                      <Col sm="8">
                        <Form.Group>
                          <label>Issues 1</label>
                          <Form.Control
                            value={temuan[0]}
                            onChange={(e) => handleChange(0, e)}
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>

                      <Col sm="4" className="tex-right mt-4">
                        <Button
                          variant="link"
                          className="btn-icon-hover"
                          onClick={handleAddColom}
                        >
                          <i class=" text-info fas fa-plus fa-2x"></i>
                        </Button>
                      </Col>
                    </Row>
                    {temuan.slice(1).map((field, index) => (
                      <Row key={index + 1} className="align-items-center">
                        <Col sm="8">
                          <Form.Group>
                            <label>Issues {index + 2}</label>
                            <Form.Control
                              value={field}
                              onChange={(e) => handleChange(index + 1, e)}
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col sm="4" className="tex-right mt-4">
                          <Button
                            variant="link"
                            className="btn-icon-hover"
                            onClick={() => handleRemoveField(index + 1)}
                          >
                            <i class=" text-danger fas fa-minus fa-2x fa-2x"></i>
                          </Button>
                        </Col>
                      </Row>
                    ))}

                    <div className="clearfix"></div>
                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submitCsVisit}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReactTables;
