import React from "react";
import Swal from "sweetalert2";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import {
  getTraining,
  verifikasiTraining,
  tambahTraining,
  editTraining,
  kirimReportTraining,
  deleteTraining,
  getCabangAktif,
} from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";

function ReactTables() {
  const dispatch = useDispatch();
  const date = new Date();
  const training = useSelector((state) => state.trainingReducer);
  const cabang = useSelector((state) => state.cabangReducer);
  const [modal, setModal] = React.useState(false);
  const [modalTraining, setModalTraining] = React.useState(false);
  const [modalEditTraining, setModalEditTraining] = React.useState(false);
  const [modalTrainingApprove, setModalTrainingApprove] = React.useState(false);
  const [dataTraining, setDataTraining] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const [deskripsi, setDeskripsi] = React.useState("");
  const [jenis, setJenis] = React.useState("");
  const [trainingId, setTrainingId] = React.useState("");
  const [deskripsiTraining, setDeskripsiTraining] = React.useState("");
  const [noteTraining, setNoteTraining] = React.useState("");
  const [dariTgl, setDariTgl] = React.useState(
    // new Date(date.getFullYear(), date.getMonth(), 1).setHours(0, 0, 0, 0)
    new Date().setHours(0, 0, 0, 0)
  );
  const [tglPelaksanaan, setTglPelaksanaan] = React.useState(new Date());
  const [smpTgl, setSmpTgl] = React.useState(new Date().setHours(23, 59, 0, 0));
  const [gambar, setGambar] = React.useState([]);
  const respon = "Done";
  const [client, setClient] = React.useState({
    value: "",
    label: "Choose the Client",
    isDisabled: true,
  });
  const [listCabang, setListCabang] = React.useState([]);
  const [modalPreviewFoto, setModalPreviewFoto] = React.useState(false);

  let tanggalSekarang = new Date();
  function onsubmit(e) {
    e.preventDefault();
    let fd = new FormData();
    fd.append("trainingId", dataTraining._id);
    fd.append("respon", status);
    fd.append("alasan", deskripsi);

    verifikasiTraining(fd);
  }

  function submitTraining(e) {
    e.preventDefault();
    Swal.fire({
      title: "Confirm",
      text: "Are you sure you want to add this training?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        if (
          !client.idCabang ||
          !jenis ||
          !noteTraining ||
          gambar.length === 0
        ) {
          Swal.fire({
            title: "Please complete the form!",
            icon: "error",
          });
        } else {
          Swal.fire({
            title: "Loading ...",
            didOpen() {
              Swal.showLoading();
            },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          });
          let formData = new FormData();

          formData.append("keterangan", noteTraining);
          formData.append("jenisTraining", jenis);
          formData.append("idCabang", client.idCabang);
          formData.append("idClient", client.idClient);
          formData.append("startDate", new Date(dariTgl).toISOString());
          formData.append("deadlineDate", new Date(smpTgl).toISOString());
          formData.append("tglPelaksanaan", tglPelaksanaan);
          gambar.map((val, index) => {
            formData.append("buktiSelesai", val);
          });
          tambahTraining(formData).then((response) => {
            if (response.status === 200) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
              getTraining(dispatch);
              setModalTraining(false);
            } else if (response.status === 400) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response.data.message,
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response.data.message,
              });
            }
          });
        }
      }
    });
    // tambahTraining({
    //   jenisTraining: jenis,
    //   noteTraining,
    //   tglPelaksanaan,
    //   gambar,
    //   startDate: new Date(dariTgl).toISOString(),
    //   deadlineDate: new Date(smpTgl).toISOString(),
    //   idClient: client.idClient,
    //   idCabang: client.idCabang,
    // });
  }
  function handleOpenModal(e) {
    setClient({
      value: "",
      label: "Choose the Client",
      isDisabled: true,
    });
    setJenis("");
    setDeskripsiTraining("");
    setDariTgl(new Date().setHours(0, 0, 0, 0));
    setSmpTgl(new Date().setHours(23, 59, 0, 0));
    setNoteTraining("");
    setTglPelaksanaan(new Date());
    setGambar([]);
  }
  function handleTindakLanjut(e) {
    setNoteTraining("");
    setTglPelaksanaan(new Date());
    setGambar([]);
  }
  React.useEffect(() => {
    getCabangAktif(dispatch);
  }, []);
  function submitReportTraining(e) {
    e.preventDefault();

    kirimReportTraining({
      noteTraining,
      gambar,
      trainingId,
      respon,
      tglPelaksanaan,
    });
  }

  function edit(e) {
    e.preventDefault();
    editTraining({
      trainingId: trainingId,
      startDate: new Date(dariTgl).toISOString(),
      deadlineDate: new Date(smpTgl).toISOString(),
    });
  }
  React.useEffect(() => {
    let tmp = cabang.getCabangAktif.map((val, index) => {
      return {
        ...val,
        label: `${val.namaCabang} - ${val.namaClient}`,
        value: val.idCabang,
      };
    });
    setListCabang(tmp);
  }, [cabang.getCabangAktif]);

  const history = useHistory();
  React.useEffect(() => {
    let tmp = training.listTraining.map((val, index) => {
      return {
        ...val,
        tglMulai: moment(val.startDate).format("DD MMM YYYY / HH:mm"),
        tglSelesai: moment(val.deadlineDate).format("DD MMM YYYY / HH:mm"),
        tglTraining: moment(val.solvedDate).format("DD MMM YYYY"),
        namaCabang: val.namaCabang ? val.namaCabang : "-",
        namaClient: val.namaClient ? val.namaClient : "-",
        actions:
          // we've added some custom button actions
          val.status == "Waiting for Approval" ? (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setDataTraining(val);
                }}
                variant="warning"
                size="md"
                className="btn-link remove text-warning"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ) : val.status == "Rejected" ? (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModalEditTraining(true);
                  setDataTraining(val);
                }}
                variant="secondary"
                size="md"
                className="btn-link remove text-secondary"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ) : val.status == "Received" ? (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModalEditTraining(true);
                  setTrainingId(val._id);
                }}
                disabled={val.edited === true}
                variant="info"
                size="md"
                className="btn-link remove text-info"
              >
                <i className="fa fa-edit fa-lg" />
              </Button>{" "}
              <Button
                onClick={() => {
                  setModalTrainingApprove(true);
                  setTrainingId(val._id);
                  handleTindakLanjut();
                }}
                variant="warning"
                size="md"
                className="btn-link remove text-warning"
              >
                <i className="fa fa-check fa-lg" />
              </Button>{" "}
              <Button
                onClick={() => {
                  setModal(true);
                  setDataTraining(val);
                }}
                variant="success"
                size="md"
                className="btn-link remove text-success"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
              {/* <Button
                onClick={() => {
                  deleteTraining(val._id);
                }}
                variant="danger"
                size="md"
                className="btn-link remove text-danger"
              >
                <i className="fa fa-times fa-lg" />
              </Button> */}
            </div>
          ) : val.status == "Done" && val.solvedInTime == true ? (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setDataTraining(val);
                }}
                variant="info"
                size="md"
                className="btn-link remove text-info"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ) : (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setDataTraining(val);
                }}
                variant="danger"
                size="md"
                className="btn-link remove text-danger"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ),
      };
    });
    setData(tmp);
  }, [training.listTraining]);
  const validDate = (current) => {
    // Hanya izinkan tanggal hari ini dan setelahnya
    return current.isSameOrAfter(new Date(), "day");
  };
  React.useEffect(() => {
    getTraining(dispatch);
  }, []);
  return (
    <>
      <div
        id="previewImage"
        style={{
          display: modalPreviewFoto ? "block" : "none",
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          zIndex: "9999",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={modalPreviewFoto}
          alt="Preview"
          style={{
            borderRadius: "5px",
            boxShadow: "4px 4px 8px 0px rgba(0,0,0,0.75)",
            maxHeight: "80%",
            maxWidth: "80%",
            objectFit: "contain",
            background: "transparent",
            position: "absolute",
            top: "50%", // Posisi vertikal ke tengah
            left: "50%", // Posisi horizontal ke tengah
            transform: "translate(-50%, -50%)",
          }}
        />
        <button
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            fontSize: "20px",
            cursor: "pointer",
          }}
          onClick={() => setModalPreviewFoto("")}
        >
          &times;
        </button>
      </div>
      <Container fluid>
        {/* <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModal(true);
          }}
          variant="primary"
        >
          Pengajuan Project
        </Button> */}
        <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModalTraining(true);
            handleOpenModal();
          }}
          variant="primary"
        >
          Add Training Matrix
        </Button>
        <Row>
          <Col md="12">
            <h4 className="title">Training Matrix</h4>

            <Card>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Training",
                      accessor: "jenisTraining",
                    },
                    {
                      Header: "Submitter",
                      accessor: "diajukanOleh",
                    },
                    {
                      Header: "Training Date",
                      accessor: "tglTraining",
                    },
                    {
                      Header: "Branch",
                      accessor: "namaCabang",
                    },
                    {
                      Header: "Client",
                      accessor: "namaClient",
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                    },

                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
          setStatus("");
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Detail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {dataTraining.status == "Waiting for Approval" ? (
            <Row>
              <Col md="12">
                <Card>
                  <Card.Body>
                    <Form.Group>
                      <label>Status</label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="singleSelect"
                        value={status.val}
                        onChange={(val) => setStatus(val.value)}
                        options={[
                          { value: "Received", label: "Received" },
                          { value: "Rejected", label: "Rejected" },
                        ]}
                        placeholder="-Choose-"
                      />
                    </Form.Group>
                    {status == "Rejected" ? (
                      <Form.Group>
                        <label>Description</label>
                        <Form.Control
                          onChange={(e) => {
                            setDeskripsi(e.target.value);
                            // setProject({
                            //   ...project,
                            //   deskripsi: e.target.value,
                            // });
                          }}
                          placeholder="Please Write Project Description"
                          as="textarea"
                        ></Form.Control>
                      </Form.Group>
                    ) : null}
                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={onsubmit}
                    >
                      Submit
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Body>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Training
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataTraining.jenisTraining}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Submitter
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataTraining.diajukanOleh}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Branch
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataTraining.namaCabang}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Client
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataTraining.namaClient}</Col>
                    </Row>
                    {/* <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Start Date
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {moment(dataTraining.startDate).format(
                          "DD MMM YYYY / HH:mm"
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        End Date
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {moment(dataTraining.deadlineDate).format(
                          "DD MMM YYYY / HH:mm"
                        )}
                      </Col>
                    </Row> */}
                    {/* <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Description
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataTraining.deskripsi}</Col>
                    </Row> */}

                    {/* <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        {" "}
                        Responded By
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {dataTraining.diresponOleh
                          ? dataTraining.diresponOleh
                          : "-"}
                      </Col>
                    </Row> */}
                    {/* <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        {" "}
                        On Schedule
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {dataTraining.status != "Done"
                          ? "-"
                          : dataTraining.solvedInTime
                          ? "Yes"
                          : "No"}
                      </Col>
                    </Row> */}
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Training Date
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {!dataTraining.solvedDate
                          ? "-"
                          : moment(dataTraining.solvedDate).format(
                              "DD MMM YYYY "
                            )}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        {" "}
                        Status
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataTraining.status}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        {" "}
                        Description
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {!dataTraining.keterangan
                          ? "-"
                          : dataTraining.keterangan}
                      </Col>
                    </Row>

                    <Row style={{ marginTop: 30 }}>
                      <Col
                        sm="12"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Final Documentation
                      </Col>
                    </Row>
                    <Row>
                      {!dataTraining.buktiSelesai ||
                      dataTraining.buktiSelesai.length == 0 ? (
                        <Col sm="12" style={{ textAlign: "center" }}>
                          Final Documentation is Not Available
                        </Col>
                      ) : (
                        dataTraining.buktiSelesai.map((val, index) => {
                          return (
                            <>
                              <Col sm="4">
                                <Image
                                  src={val}
                                  style={{
                                    width: 200,
                                    height: 250,
                                    margin: 20,
                                  }}
                                  thumbnail
                                  onClick={() => {
                                    setModalPreviewFoto(val);
                                  }}
                                />
                              </Col>
                            </>
                          );
                        })
                      )}
                    </Row>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={modalTraining}
        onHide={() => {
          setModalTraining(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Add Training Matrix</Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Client's Name</label>
                          <Select
                            className="react-select primary pb-2"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            onChange={(value) => {
                              setClient(value);
                            }}
                            value={client}
                            options={[
                              {
                                value: "",
                                label: "Choose the Client",
                                isDisabled: true,
                              },
                              ...listCabang,
                              "-",
                            ]}
                            placeholder="Single Select"
                          />
                        </Form.Group>
                        <Form.Group>
                          <label>Training Name</label>
                          <Form.Control
                            onChange={(e) => {
                              setJenis(e.target.value);
                              // setDataBaru({
                              //   ...dataBaru,
                              //   gedung: e.target.value,
                              // });
                            }}
                            // placeholder="Masukan Nama Gedung"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Description</label>
                          <Form.Control
                            onChange={(e) => {
                              setDeskripsiTraining(e.target.value);
                              // setProject({
                              //   ...project,
                              //   deskripsi: e.target.value,
                              // });
                            }}
                            // placeholder="Tuliskan Deskripsi Project"
                            as="textarea"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row> */}
                    {/* <Row>
                      <Col sm="6">
                        <label>Start Date</label>
                        <Form.Group>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                            }}
                            onChange={(e) => {
                              setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                            }}
                            value={dariTgl}
                            initialValue={dariTgl}
                            timeFormat={false}
                          ></ReactDatetime>
                        </Form.Group>
                      </Col>
                      <Col sm="6">
                        <label>End Date</label>
                        <Form.Group>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                            }}
                            onChange={(e) => {
                              setSmpTgl(e.toDate().setHours(23, 59, 0, 0));
                            }}
                            value={smpTgl}
                            initialValue={smpTgl}
                            timeFormat={false}
                          ></ReactDatetime>
                        </Form.Group>
                      </Col>
                    </Row> */}
                    <Row>
                      <Col>
                        <label>Training Date</label>
                        <Form.Group>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                              readOnly: true,
                              style: {
                                cursor: "default",
                                backgroundColor: "white",
                                color: "black !important",
                              },
                            }}
                            onChange={(e) => {
                              setTglPelaksanaan(e.toDate());
                            }}
                            value={tglPelaksanaan}
                            initialValue={tglPelaksanaan}
                            // isValidDate={validDate}
                            timeFormat={false}
                          ></ReactDatetime>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Description</label>
                          <Form.Control
                            onChange={(e) => {
                              setNoteTraining(e.target.value);
                              // setProject({
                              //   ...project,
                              //   deskripsi: e.target.value,
                              // });
                            }}
                            // placeholder="Tuliskan Deskripsi Project"
                            as="textarea"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <label
                      className="label-input-file btn btn-danger text-white mb-4"
                      for="import2"
                    >
                      Add Photo
                      <input
                        type="file"
                        id="import2"
                        onClick={(e) => {
                          e.target.value = "";
                        }}
                        onChange={(e) => {
                          setGambar([...gambar, ...Array.from(e.target.files)]);
                        }}
                        style={{ display: "none" }}
                        accept="image/*"
                        multiple
                      />
                    </label>
                    {gambar.map((val, index) => {
                      return (
                        <div className="d-flex align-items-start">
                          <p>{val.name}</p>
                          <Button
                            onClick={() => {
                              setGambar([
                                ...gambar.slice(0, index),
                                ...gambar.slice(index + 1, gambar.length),
                              ]);
                            }}
                            variant="danger"
                            size="sm"
                            className="btn-link remove text-danger"
                          >
                            <i className="fa fa-times" />
                          </Button>
                        </div>
                      );
                    })}
                    <Row>
                      <Button
                        className="ml-3 btn-fill pull-right"
                        type="submit"
                        variant="info"
                        onClick={submitTraining}
                      >
                        Submit
                      </Button>
                    </Row>

                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={modalEditTraining}
        onHide={() => {
          setModalEditTraining(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Edit</Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="6">
                        <label>Start Date</label>
                        <Form.Group>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                            }}
                            onChange={(e) => {
                              setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                            }}
                            value={dariTgl}
                            initialValue={dariTgl}
                            timeFormat={false}
                          ></ReactDatetime>
                        </Form.Group>
                      </Col>
                      <Col sm="6">
                        <label>End Date</label>
                        <Form.Group>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                            }}
                            onChange={(e) => {
                              setSmpTgl(e.toDate().setHours(23, 59, 0, 0));
                            }}
                            value={smpTgl}
                            initialValue={smpTgl}
                            timeFormat={false}
                          ></ReactDatetime>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={edit}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={modalTrainingApprove}
        onHide={() => {
          setModalTrainingApprove(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Report Training</Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <label>Implimentation Date</label>
                        <Form.Group>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                            }}
                            onChange={(e) => {
                              setTglPelaksanaan(e.toDate());
                            }}
                            value={tglPelaksanaan}
                            initialValue={tglPelaksanaan}
                            // isValidDate={validDate}
                            timeFormat={true}
                          ></ReactDatetime>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Description</label>
                          <Form.Control
                            onChange={(e) => {
                              setNoteTraining(e.target.value);
                              // setProject({
                              //   ...project,
                              //   deskripsi: e.target.value,
                              // });
                            }}
                            // placeholder="Tuliskan Deskripsi Project"
                            as="textarea"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <label
                      className="label-input-file btn btn-danger text-white mb-4"
                      for="import2"
                    >
                      Add Photo
                      <input
                        type="file"
                        id="import2"
                        onClick={(e) => {
                          e.target.value = "";
                        }}
                        onChange={(e) => {
                          setGambar([...gambar, ...Array.from(e.target.files)]);
                        }}
                        style={{ display: "none" }}
                        accept="image/*"
                        multiple
                      />
                    </label>
                    {gambar.map((val, index) => {
                      return (
                        <div className="d-flex align-items-start">
                          <p>{val.name}</p>
                          <Button
                            onClick={() => {
                              setGambar([
                                ...gambar.slice(0, index),
                                ...gambar.slice(index + 1, gambar.length),
                              ]);
                            }}
                            variant="danger"
                            size="sm"
                            className="btn-link remove text-danger"
                          >
                            <i className="fa fa-times" />
                          </Button>
                        </div>
                      );
                    })}

                    <div className="clearfix"></div>
                  </Card.Body>
                  <Card.Footer>
                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submitReportTraining}
                    >
                      Submit
                    </Button>
                  </Card.Footer>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReactTables;
