import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import moment from "moment";

export function createList(data) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEnterKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .post("checklist/createlist", data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      });
    })
    .then(() => {
      window.location.reload();
    })
    .catch(errorHandler);
}

export function getList(dispacth, data) {
  baseAxios
    .get("/checklist/getlist", {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispacth({ type: "SET_GETLISTCHECKLIST", data: respon.data });
    });
}
export function createFormChecklist(data) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEnterKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .post("checklist/createchecklist", data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      });
    })
    .then(() => {
      window.location.reload();
    })
    .catch(errorHandler);
}

export function getLaporanChecklist(dispacth, data) {
  baseAxios
    .get("/checklist/getlaporanchecklist", {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispacth({ type: "SET_GETLAPORANCHECKLIST", data: respon.data });
    });
}
